import React from 'react';
import {Button} from "@mantine/core";
import { MantineProvider } from "@mantine/core";
import '../App.css'
import '@mantine/core/styles.css'

export default function TextComponent() {
    return (
        <MantineProvider>
            <Button>Text Component</Button>
        </MantineProvider>
    );
}