import { Badge, Button, Flex, Image, Paper, Title } from "@mantine/core";
import { IconX, IconPlus } from "@tabler/icons-react";
import React, { useState } from "react";
import {ImageData, SuggestionNewEvent, GroupSuggestion} from './GroupInterfaces';

// interface SuggestedImage {
//     key: string;
//     thumbnail_url: string;
//     image_url: string;
// }
//
// interface GroupData {
//     community: string;
//     suggested: SuggestedImage[];
// }

interface NewEventProps {
    group: SuggestionNewEvent; // Changed from onSubmit to group
    onSubmit: (seed: string, selected: string[], unselected: string[]) => void;
}

// export interface ImageData {
//     key: string;
//     thumbnail_url: string;
//     image_url?: string;
// }
//
// interface SuggestionBasics {
//     key: string;
//     score?: number;
//     type: 'newEvent' | 'addImageToEvent' | 'mergeEvents'; // Discriminant property
// }
//
// export interface SuggestionNewEvent extends SuggestionBasics {
//     type: 'newEvent';
//     images: ImageData[];
// }


export function NewEvent({ group, onSubmit }: NewEventProps) {
    const [selectedImages, setSelectedImages] = useState<Set<string>>(new Set(group.images.map(image => image.key)));
    const [unselectedImages, setUnselectedImages] = useState<Set<string>>(new Set());
    const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({});

    const handleRemoveImage = async (imageKey: string) => {
        setLoadingStates(prev => ({ ...prev, [imageKey]: true }));

        await setSelectedImages(prevSelected => {
            const updated = new Set(prevSelected);
            updated.delete(imageKey);
            return updated;
        });

        await setUnselectedImages(prevUnselected => {
            const updated = new Set(prevUnselected);
            updated.add(imageKey);
            return updated;
        });

        setLoadingStates(prev => ({ ...prev, [imageKey]: false }));
    };

    const handleAddImage = async (imageKey: string) => {
        setLoadingStates(prev => ({ ...prev, [imageKey]: true }));

        await setSelectedImages(prevSelected => {
            const updated = new Set(prevSelected);
            updated.add(imageKey);
            return updated;
        });

        await setUnselectedImages(prevUnselected => {
            const updated = new Set(prevUnselected);
            updated.delete(imageKey);
            return updated;
        });

        setLoadingStates(prev => ({ ...prev, [imageKey]: false }));
    };

    const handleNoneClick = () => {
        const unselectedArray = Array.from(selectedImages).concat(Array.from(unselectedImages));
        const success = onSubmit(
            group.key,
            [],
            unselectedArray
        );
        console.log(success);
    };

    const handleYesClick = () => {
        const selectedArray = Array.from(selectedImages);
        const unselectedArray = Array.from(unselectedImages);
        const success = onSubmit(
            group.key,
            selectedArray,
            unselectedArray
        );
        console.log(success);
    };

    return (
        <Paper shadow="sm" radius="lg" withBorder p="xl" style={{ marginBottom: '20px', padding: '10px', backgroundColor: '#f0f0f0' }}>
            <Title order={3} mb={'lg'}>Are these images from the same event?</Title>
            <Flex
                mt="md"
                direction="row"
                wrap="wrap"
                gap={{ base: '3px', sm: '3px' }}
                justify="flex-start"
            >
                {group.images.map(image => (
                    <div key={image.key} style={{
                        position: 'relative',
                        width: 'calc((100% - 12px) / 5)',
                        aspectRatio: 1 / 1
                    }}>
                        <Image
                            src={image.thumbnail_url}
                            alt="Thumbnail"
                            style={{
                                aspectRatio: 1 / 1,
                                cursor: 'pointer',
                                opacity: selectedImages.has(image.key) ? 1 : 0.5
                            }}
                        />
                        {selectedImages.has(image.key) ? (
                            <Badge
                                size="md"
                                circle
                                onClick={() => {
                                    if (!loadingStates[image.key]) {
                                        handleRemoveImage(image.key);
                                    }
                                }}
                                style={{
                                    position: 'absolute',
                                    top: '4px',
                                    right: '4px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                    cursor: 'pointer',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                onMouseEnter={(e) => {
                                    if (!loadingStates[image.key]) {
                                        e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 1.0)';
                                    }
                                }}
                                onMouseLeave={(e) => {
                                    if (!loadingStates[image.key]) {
                                        e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.7)';
                                    }
                                }}>
                                <IconX color="black" size={'100%'}
                                       style={{
                                           position: 'absolute',
                                           top: '0px',
                                           right: '0px'
                                       }}
                                />
                            </Badge>
                        ) : (
                            <Badge
                                size="md"
                                circle
                                onClick={() => {
                                    if (!loadingStates[image.key]) {
                                        handleAddImage(image.key);
                                    }
                                }}
                                style={{
                                    position: 'absolute',
                                    top: '4px',
                                    right: '4px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                    cursor: 'pointer',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                onMouseEnter={(e) => {
                                    if (!loadingStates[image.key]) {
                                        e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 1.0)';
                                    }
                                }}
                                onMouseLeave={(e) => {
                                    if (!loadingStates[image.key]) {
                                        e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.7)';
                                    }
                                }}>
                                <IconPlus color="black" size={'100%'}
                                          style={{
                                              position: 'absolute',
                                              top: '0px',
                                              right: '0px'
                                          }}
                                />
                            </Badge>
                        )}
                    </div>
                ))}
            </Flex>
            <Flex justify={{ sm: 'right' }} gap="sm" mt="md">
                <Button color="gray" variant="outline" onClick={handleNoneClick}>
                    None
                </Button>
                <Button color="blue" onClick={handleYesClick}>
                    Yes, Create Group
                </Button>
            </Flex>
        </Paper>
    );
}

// import {Badge, Button, Flex, Image, Paper, Title} from "@mantine/core";
// import {IconX, IconPlus} from "@tabler/icons-react";
// import React, {useEffect, useState} from "react";
//
// interface SuggestedImage {
//     key: string;
//     thumbnail_url: string;
//     image_url: string;
// }
//
// interface GroupData {
//     community: string;
//     suggested: SuggestedImage[];
// }
//
// interface NewEventProps {
//     onSubmit: (seed: string, selected: string[], unselected: string[]) => void;
// }
//
// export function NewEvent({ onSubmit }: NewEventProps) {
//     const [groups, setGroups] = useState<GroupData[]>([]);
//     const [selectedImages, setSelectedImages] = useState<{ [key: string]: Set<string> }>({});
//     const [unselectedImages, setUnselectedImages] = useState<{ [key: string]: Set<string> }>({});
//     // const [loading, setLoading] = useState<boolean>(false);
//     const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({});
//     useEffect(() => {
//                 const hardcodedGroups: GroupData[] = [
//             {
//                 community: '1',
//                 suggested: [
//                     {
//                         key: 'image24v',
//                         thumbnail_url: '/assets/train-lnl/24V.jpg',
//                         image_url: '/assets/train-lnl/24V.jpg'
//                     },
//                     {
//                         key: 'image24y',
//                         thumbnail_url: '/assets/train-lnl/24Y.jpg',
//                         image_url: '/assets/train-lnl/24Y.jpg'
//                     },
//                     {
//                         key: 'image24z',
//                         thumbnail_url: '/assets/train-lnl/24Z.jpg',
//                         image_url: '/assets/train-lnl/24Z.jpg'
//                     }
//                 ]
//             },
//             {
//                 community: '2',
//                 suggested: [
//                     {
//                         key: 'image24m',
//                         thumbnail_url: '/assets/train-lnl/24M.jpg',
//                         image_url: '/assets/train-lnl/24M.jpg'
//                     },
//                     {
//                         key: 'image24l',
//                         thumbnail_url: '/assets/train-lnl/24L.jpg',
//                         image_url: '/assets/train-lnl/24L.jpg'
//                     },
//                     {
//                         key: 'image24k',
//                         thumbnail_url: '/assets/train-lnl/24K.jpg',
//                         image_url: '/assets/train-lnl/24K.jpg'
//                     },
//                     {
//                         key: 'image24j',
//                         thumbnail_url: '/assets/train-lnl/24J.jpg',
//                         image_url: '/assets/train-lnl/24J.jpg'
//                     },
//                     {
//                         key: 'image24i',
//                         thumbnail_url: '/assets/train-lnl/24I.jpg',
//                         image_url: '/assets/train-lnl/24I.jpg'
//                     }
//                 ]
//             }
//         ];
//
//         setGroups(hardcodedGroups);
//
//         const initialSelected: { [key: string]: Set<string> } = {};
//         const initialUnselected: { [key: string]: Set<string> } = {};
//         hardcodedGroups.forEach(group => {
//             initialSelected[group.community] = new Set(group.suggested.map(image => image.key));
//             initialUnselected[group.community] = new Set();
//         });
//         setGroups(hardcodedGroups);
//         setSelectedImages(initialSelected);
//         setUnselectedImages(initialUnselected);
//     }, []);
//
//     const handleRemoveImage = async (community: string, imageKey: string) => {
//         setLoadingStates(prev => ({ ...prev, [imageKey]: true }));
//
//         await setSelectedImages(prevSelected => {
//             const updated = new Set(prevSelected[community]);
//             updated.delete(imageKey);
//             return { ...prevSelected, [community]: updated };
//         });
//
//         await setUnselectedImages(prevUnselected => {
//             const updated = new Set(prevUnselected[community]);
//             updated.add(imageKey);
//             return { ...prevUnselected, [community]: updated };
//         });
//
//         setLoadingStates(prev => ({ ...prev, [imageKey]: false }));
//     };
//
//     const handleAddImage = async (community: string, imageKey: string) => {
//         setLoadingStates(prev => ({ ...prev, [imageKey]: true }));
//
//         await setSelectedImages(prevSelected => {
//             const updated = new Set(prevSelected[community]);
//             updated.add(imageKey);
//             return { ...prevSelected, [community]: updated};
//         });
//
//         await setUnselectedImages(prevUnselected => {
//             const updated = new Set(prevUnselected[community]);
//             updated.delete(imageKey);
//             return { ...prevUnselected, [community]: updated };
//         });
//
//         setLoadingStates(prev => ({ ...prev, [imageKey]: false }));
//     };
//
//     const handleNoneClick = (community: string) => {
//         console.log("NoneClick - community", community);
//         console.log("Selected Images:", []);
//         console.log("Unselected Images:", Array.from(selectedImages[community]).concat(Array.from(unselectedImages[community])),);
//         let success = onSubmit(
//             community,
//             [],
//             Array.from(selectedImages[community]).concat(Array.from(unselectedImages[community])),
//         );
//         console.log(success);
//     };
//
//     const handleYesClick = (community: string) => {
//         console.log("YesClick - community", community);
//         console.log("Selected Images:", Array.from(selectedImages[community]));
//         console.log("Unselected Images:", Array.from(unselectedImages[community]));
//         let success = onSubmit(
//             community,
//             Array.from(selectedImages[community]),
//             Array.from(unselectedImages[community]),
//         );
//         console.log(success);
//     };
//
//     return (
//         <>
//             {groups.map(group => (
//                 <Paper shadow="sm" radius="lg" withBorder p="xl" key={group.community}
//                        style={{ marginBottom: '20px', padding: '10px', backgroundColor: '#f0f0f0' }}>
//                     <Title order={3} mb={'lg'}>Are these images from the same event?</Title>
//                     <Flex
//                         mt="md"
//                         direction="row"
//                         wrap="wrap"
//                         gap={{ base: '3px', sm: '3px' }}
//                         justify="flex-start"
//                     >
//                         {group.suggested.map(image => (
//                             <div key={image.key} style={{
//                                 position: 'relative',
//                                 width: 'calc((100% - 12px) / 5)',
//                                 aspectRatio: 1 / 1
//                             }}>
//                                 <Image
//                                     src={image.thumbnail_url}
//                                     alt="Thumbnail"
//                                     style={{
//                                         aspectRatio: 1 / 1,
//                                         cursor: 'pointer',
//                                         opacity: selectedImages[group.community].has(image.key) ? 1 : 0.5
//                                     }}
//                                 />
//                                 {selectedImages[group.community].has(image.key) ? (
//                                     <Badge
//                                         size="md"
//                                         circle
//                                         onClick={() => {
//                                             if (!loadingStates[image.key]) {
//                                                 handleRemoveImage(group.community, image.key);
//                                             }
//                                         }}
//                                         style={{
//                                             position: 'absolute',
//                                             top: '4px',
//                                             right: '4px',
//                                             backgroundColor: loadingStates[image.key] ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 0.7)',
//                                             cursor: loadingStates[image.key] ? 'not-allowed' : 'pointer',
//                                             alignItems: 'center',
//                                             justifyContent: 'center',
//                                         }}
//                                         onMouseEnter={(e) => {
//                                             if (!loadingStates[image.key]) {
//                                                 e.currentTarget.style.backgroundColor = 'rgba(255, 0, 0, 0.9)';
//                                             }
//                                         }}
//                                         onMouseLeave={(e) => {
//                                             if (!loadingStates[image.key]) {
//                                                 e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.7)';
//                                             }
//                                         }}>
//                                         <IconX color="black" size={'100%'}
//                                                style={{
//                                                    position: 'absolute',
//                                                    top: '0px',
//                                                    right: '0px'
//                                                }}
//                                         />
//                                     </Badge>
//                                 ) : (
//                                     <Badge
//                                         size="md"
//                                         circle
//                                         onClick={() => {
//                                             if (!loadingStates[image.key]) {
//                                                 handleAddImage(group.community, image.key);
//                                             }
//                                         }}
//                                         style={{
//                                             position: 'absolute',
//                                             top: '4px',
//                                             right: '4px',
//                                             backgroundColor: loadingStates[image.key] ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 0.7)',
//                                             cursor: loadingStates[image.key] ? 'not-allowed' : 'pointer',
//                                             alignItems: 'center',
//                                             justifyContent: 'center',
//                                         }}
//                                         onMouseEnter={(e) => {
//                                             if (!loadingStates[image.key]) {
//                                                 e.currentTarget.style.backgroundColor = 'rgba(0, 255, 0, 0.9)';
//                                             }
//                                         }}
//                                         onMouseLeave={(e) => {
//                                             if (!loadingStates[image.key]) {
//                                                 e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.7)';
//                                             }
//                                         }}>
//                                         <IconPlus color="black" size={'100%'}
//                                                   style={{
//                                                       position: 'absolute',
//                                                       top: '0px',
//                                                       right: '0px'
//                                                   }}
//                                         />
//                                     </Badge>
//                                 )}
//                             </div>
//                         ))}
//                     </Flex>
//                     <Flex justify={{ sm: 'right' }} gap="sm" mt="md">
//                         <Button color="gray" variant="outline" onClick={() => handleNoneClick(group.community)}>
//                             None
//                         </Button>
//                         <Button color="blue" onClick={() => handleYesClick(group.community)}>
//                             Yes, Create Group
//                         </Button>
//                     </Flex>
//                 </Paper>
//             ))}
//         </>
//     );
// }