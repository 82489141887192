import React from 'react';
import { useForm } from '@mantine/form';
import { Group, Button, Text, PinInput, Stack, Anchor } from '@mantine/core';

interface VerificationCodeProps {
    handleVerification: (verificationCode: string, onCodeError: (correctCode: boolean) => void) => void;
}

const VerificationCode: React.FC<VerificationCodeProps> = ({ handleVerification }) => {
    const [pinInputError, setPinInputError] = React.useState(false);
    const form = useForm({
        initialValues: {
            verificationCode: '',
        },
        validate: {
            verificationCode: (val) => (val.length === 6 ? null : 'Invalid code'),
        },
    });

    return (
        <>
            <Text size="lg" fw={500}>
                Verify Your Account
            </Text>
            <Text size="sm" color="dimmed" mt="md">
                Please enter the 6-digit code we sent to your email:
            </Text>
            <form onSubmit={form.onSubmit((values) => handleVerification(values.verificationCode, setPinInputError))}>
                <Stack align="center" mt="lg">
                    <PinInput
                        oneTimeCode={true}
                        error={pinInputError}
                        placeholder={""}
                        length={6}
                        value={form.values.verificationCode}
                        onChange={(value) => form.setFieldValue('verificationCode', value)}
                    />
                    <Group justify="space-between" mt="xl" style={{ width: '100%' }}>
                        <Anchor component="button" type="button" c="dimmed" size="xs">
                            Not getting a code? Resend
                        </Anchor>
                        <Button type="submit" radius="xl">
                            Submit
                        </Button>
                    </Group>
                </Stack>
            </form>
        </>
    );
}

export default VerificationCode;

// import React from 'react';
// import { useForm } from '@mantine/form';
// import { Group, Button, Text, PinInput, Stack } from '@mantine/core';
// import {Anchor} from "@mantine/core";
//
// interface VerificationCodeProps {
//     handleVerification: (verificationCode: string) => void;
// }
//
// const VerificationCode: React.FC<VerificationCodeProps> = ({ handleVerification }) => {
//     const form = useForm({
//         initialValues: {
//             verificationCode: '',
//         },
//         validate: {
//             verificationCode: (val) => (val.length === 6 ? null : 'Invalid code'),
//         },
//     });
//
//     return (
//         <><Text size="lg" fw={500}>
//             Verify Your Account
//         </Text>
//             <Text size="sm" color="dimmed" mt="md">
//                 Please enter the 6-digit code we sent to your email:
//             </Text>
//             <form onSubmit={form.onSubmit((values) => handleVerification(values.verificationCode))}>
//                 <Stack align="center" mt="lg">
//                     <Group align="center">
//                     <PinInput
//                         placeholder=""
//                         length={6}
//                         value={form.values.verificationCode}
//                         onChange={(value) => form.setFieldValue('verificationCode', value)}
//                     />
//                     </Group>
//                     <Group justify="space-between" mt="xl" style={{ width: '100%' }}>
//                         <Anchor component="button" type="button" c="dimmed" size="xs">
//                             Not getting a code? Resend
//                         </Anchor>
//                         <Button type="submit" radius="xl">
//                             Submit
//                         </Button>
//                     </Group>
//                 </Stack>
//             </form>
//         </>
//     );
// }
//
// export default VerificationCode;
