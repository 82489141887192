import React, {useState, useEffect} from 'react';
import {Flex, Card, Avatar, Text, Blockquote, Modal, TextInput, Button, Fieldset, Title, Divider, Skeleton} from '@mantine/core';
import {IconPlus, IconInfoCircle} from '@tabler/icons-react';
import FaceSelection from "./FaceSelection";
import {DateInput} from '@mantine/dates';
import axios from "axios";
import AdditionalFaces from "./AdditionalFaces";
import {PaperProps} from "@mantine/core/lib";
import {AuthenticationResultType} from "@aws-sdk/client-cognito-identity-provider";
import {FaceData} from "./FaceDataInterface";


interface FamilyMember {
    id: string;
    name: string;
    dateOfBirth: string;
    profilePictureUrl: string;
}



interface FamilyProps extends PaperProps {
    userId: string,
    auth: AuthenticationResultType,
}

export function FamilyTree({ userId, auth }: FamilyProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [familyMembers, setFamilyMembers] = useState<FamilyMember[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [nextModalOpen, setNextModalOpen] = useState(false);
    const [newMember, setNewMember] = useState({firstName: '', lastName: '', dateOfBirth: '', faceImage: ''});
    const [faces, setFaces] = useState<FaceData[]>([]);
    const [selectedFace, setSelectedFace] = useState<FaceData | null>(null);

    useEffect(() => {
        fetchFamilyMembers();
        // fetchUnnamedFaces();
    }, []);

    const fetchFamilyMembers = async () => {

        try {

            let apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/person";
            let id_token = auth.IdToken;

            const response = await axios.get(apiUrl, {
                params: {
                    sub: userId
                },
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });

            // console.log(response.data)
            const familyMembers: FamilyMember[] = typeof response.data.body === 'string'
                ? JSON.parse(response.data.body)
                : response.data.body;

            console.log(familyMembers);
            setFamilyMembers(familyMembers);

            // let apiUrl = "https://kc5a6afno5.execute-api.us-west-2.amazonaws.com/test3/people";
            // let id_token = auth.IdToken;
            // let sub = userId;
            //
            // console.log("id_token:", id_token);
            // console.log("sub:", sub);
            //
            // const response = await axios.post<FamilyMember[]>(apiUrl, {uid: sub}, {
            //     headers: {
            //         'Authorization': `Bearer ${id_token}`,
            //         'Content-Type': 'application/json'
            //     }
            // });
            //
            // console.log('API response:', response.data);
            // setFamilyMembers(response.data);
        } catch (error) {
            console.error('Error fetching family members:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchUnnamedFaces = async () => {
        try {
            let apiUrl = "https://kc5a6afno5.execute-api.us-west-2.amazonaws.com/test3/unknown-faces";
            let id_token = auth.IdToken;
            let sub = userId;
            const response = await axios.post<FaceData[]>(apiUrl, {uid: sub}, {
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });
            console.log('API response:', response.data);
            setFaces(response.data)
        } catch (error) {
            console.error('Error fetching unnamed faces:', error);
            // todo: handle error
        }
    };

    const handleFaceClick = (face: FaceData) => {
        setSelectedFace(face);
    };

    const handleModalOpen = () =>  {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);

    };

    const handleProgressToNextModal = () => {
        setNextModalOpen(true);
        handleModalClose();
    }
    const handleNextModalClose = () => {
        setNextModalOpen(false);
        setNewMember({firstName: '', lastName: '', dateOfBirth: '', faceImage: ''}); // Reset input values
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setNewMember((prevMember) => ({
            ...prevMember,
            [name]: value,
        }));
    };

    const handleDateChange = (value: Date | null) => {
        setNewMember((prevMember) => ({
            ...prevMember,
            dateOfBirth: value ? value.toLocaleDateString() : '', // Convert date to MM/DD/YYYY format
        }));
    };

    const handleAddFamilyMember = async (additionalFaces: FaceData[]) => {
        //todo: can the code below be at the end so there isn't lag time to close the modal?
        await postNewPerson()
        postAdditionalFaces(additionalFaces)

        // Add validation if needed
        const newMemberData: FamilyMember = {
            id: `${newMember.firstName.toLowerCase()}-${newMember.lastName.toLowerCase()}`,
            name: `${newMember.firstName} ${newMember.lastName}`,
            dateOfBirth: newMember.dateOfBirth,
            profilePictureUrl: selectedFace ? selectedFace.url : "", // Add default profile picture URL or fetch it from somewhere
        };

        setFamilyMembers((prevMembers) => [...prevMembers, newMemberData]);
        handleNextModalClose(); // Close the modal after adding the new member
    };

    const postNewPerson = async () => {
        try {
            let apiUrl = "https://kc5a6afno5.execute-api.us-west-2.amazonaws.com/test3/create-person";
            let id_token = auth.IdToken;
            let sub = userId;
            let name = `${newMember.firstName} ${newMember.lastName}`
            let dob = newMember.dateOfBirth
            let profile_photo_key = selectedFace ? selectedFace.key : ""

            console.log("id_token:", id_token, "\nsub:", sub, "\nname:", name, "\ndob:", dob, "\nprofile_photo_key:", profile_photo_key);
            console.log("sub:", sub);

            let response2 = await axios.post(apiUrl, {uid: sub, name: name, dob: dob, profile_photo_key: profile_photo_key}, {
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });

            console.log('API response:', response2.data);
            return "success"
        } catch (error) {
            console.error('Error adding additional faces:', error);
            // todo: handle error
        }
    }

    const postAdditionalFaces = async (selectedFaces: FaceData[]) => {
        try {
            let apiUrl = "https://kc5a6afno5.execute-api.us-west-2.amazonaws.com/test3/link-faces-to-person";
            let id_token = auth.IdToken;
            let sub = userId;
            let name = `${newMember.firstName} ${newMember.lastName}`
            let photo_keys = selectedFaces ? selectedFaces.map(face => face.key) : [];

            console.log("id_token:", id_token, "\nsub:", sub, "\nname:", name, "\nphoto_keys:", photo_keys);

            let response2 = await axios.post(apiUrl, {uid: sub, name: name, photo_keys: photo_keys}, {
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });
            console.log('API response:', response2.data);
        } catch (error) {
            console.error('Error adding additional faces:', error);
            // todo: handle error
        }
    };

    const renderSkeletonCards = () => {
        return Array(4).fill(null).map((_, index) => (
            <Card
                key={`skeleton-${index}`}
                shadow="xs"
                radius="md"
                style={{
                    width: 'calc((100% - 30px) / 4)',
                    aspectRatio: '1.0',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Skeleton height={120} width={120} mb={20} radius={'xl'}/>
                    <Skeleton height={20} width={120} mb={10} />
                    <Skeleton height={15} width={80} />
                </div>
            </Card>
        ));
    };

    return (
        <Flex direction="column">
            <Title order={1}>Identify Faces</Title>
            <Divider mt='xs' />
            <Blockquote color="blue" icon={<IconInfoCircle/>} mt='lg' mb='lg'>
                <p>
                    Your family tree isn't just a record of the past—it's the key to organizing and preserving your memories. By adding family members and their birthdays, you enable Sort My Shoebox's AI to more accurately predict when your photos were taken, helping you better organize your photo collection.
                </p>
                <p>
                    In the future, this space will also serve as a hub for tracking and storing genealogical information, such as marriages, children, occupations, and significant life events. With AI-powered suggestions, we’ll help uncover the hidden clues in each photographs as you piece together your family history.
                </p>
            </Blockquote>

            <Flex
                // mt='lg'
                wrap="wrap"
                direction={{ base: 'column', sm: 'row' }}
                gap={{ base: '10px', sm: '10px' }}
                justify="flex-start"
            >
                {isLoading ? renderSkeletonCards() : familyMembers.map((member) => (
                    <Card
                        key={member.id}
                        shadow="xs"
                        radius="md"
                        style={{
                            width: 'calc((100% - 30px) / 4)',  // 4 cards per row (minus 30 for gaps)
                            aspectRatio: '1.0',
                            // height: '20%',
                            // paddingTop: '25%',  // Maintain 1:1 aspect ratio
                            position: 'relative', // To position content inside
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Avatar
                                src={member.profilePictureUrl}
                                radius="xl"
                                alt={member.name}
                                style={{
                                    width: '120px',
                                    height: '120px',
                                    marginBottom: '20px',
                                }}
                            />
                            <div style={{ textAlign: 'center' }}>
                                <Text size="lg">{member.name}</Text>
                                <Text size="sm" color="gray">
                                    {member.dateOfBirth}
                                </Text>
                            </div>
                        </div>
                    </Card>
                ))}
                {/*<Card*/}
                {/*    mt="lg"*/}
                {/*    shadow="xs"*/}
                {/*    radius="md"*/}
                {/*    style={{*/}
                {/*        width: '325px',*/}
                {/*        height: '300px',*/}
                {/*        display: 'flex',*/}
                {/*        flexDirection: 'column',*/}
                {/*        alignItems: 'center',*/}
                {/*        justifyContent: 'center',*/}
                {/*        cursor: 'pointer',*/}
                {/*        backgroundColor: '#f0f0f0',*/}
                {/*    }}*/}
                {/*    onClick={handleModalOpen}*/}
                {/*>*/}
                {/*    <IconPlus size="48px" color="gray"/>*/}
                {/*    <Text size="lg" mt="sm">*/}
                {/*        Add Person*/}
                {/*    </Text>*/}
                {/*</Card>*/}
                {/*<Modal*/}
                {/*    opened={modalOpen}*/}
                {/*    onClose={handleModalClose}*/}
                {/*    size="lg"*/}
                {/*    title="Add New Person"*/}
                {/*>*/}
                {/*    <Fieldset legend="Select Face">*/}
                {/*        <FaceSelection faces={faces} onSelect={handleFaceClick}/> /!* Face selection component *!/*/}
                {/*    </Fieldset>*/}
                {/*    <Fieldset mt='md' legend="Required Information">*/}
                {/*        <TextInput*/}
                {/*            label="First Name"*/}
                {/*            placeholder="Enter first name"*/}
                {/*            value={newMember.firstName}*/}
                {/*            onChange={handleInputChange}*/}
                {/*            name="firstName"*/}
                {/*            required*/}
                {/*            style={{marginBottom: '16px'}}*/}
                {/*        />*/}
                {/*        <TextInput*/}
                {/*            label="Last Name"*/}
                {/*            placeholder="Enter last name"*/}
                {/*            value={newMember.lastName}*/}
                {/*            onChange={handleInputChange}*/}
                {/*            name="lastName"*/}
                {/*            required*/}
                {/*            style={{marginBottom: '16px'}}*/}
                {/*        />*/}
                {/*        <DateInput*/}
                {/*            valueFormat="MM/DD/YYYY"*/}
                {/*            label="Date of Birth"*/}
                {/*            placeholder="MM/DD/YYYY"*/}
                {/*            value={newMember.dateOfBirth ? new Date(newMember.dateOfBirth) : null}*/}
                {/*            onChange={handleDateChange}*/}
                {/*            required*/}
                {/*            style={{marginBottom: '16px'}}*/}
                {/*        />*/}
                {/*    </Fieldset>*/}
                {/*    <Flex justify={{sm: 'right'}}>*/}
                {/*        <Button mt='lg' variant="outline" color="blue" onClick={handleProgressToNextModal}>*/}
                {/*            Next*/}
                {/*        </Button>*/}
                {/*    </Flex>*/}
                {/*</Modal>*/}
                {/*<Modal*/}
                {/*    opened={nextModalOpen}*/}
                {/*    onClose={handleNextModalClose}*/}
                {/*    size="lg"*/}
                {/*    title="Add New Person"*/}
                {/*>*/}
                {/*    <AdditionalFaces name={newMember.firstName} face={selectedFace? selectedFace.rekognition_id : ''}*/}
                {/*                     onSelect={handleAddFamilyMember} auth={auth} userId={userId}/>*/}
                {/*</Modal>*/}
            </Flex>
        </Flex>
    );
};

export default FamilyTree;
