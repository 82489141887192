import React, {useEffect, useState} from 'react';
import {useDisclosure} from '@mantine/hooks';
import {AppShell, Burger, Flex, MantineProvider, PaperProps, Switch} from '@mantine/core';
import {LeftNavMenu} from './components/LeftNavMenu'; // Import the LeftNavMenu component
import TextComponent from './components/Text';
import "./logo-font.css"
import '@mantine/carousel/styles.css';
import {IconChevronDown} from '@tabler/icons-react';
import FamilyTree from "./components/CreateFamilyMember"
import SortOptionsMenu from "./components/sortOptionsMenu";
import DeleteDuplicates from "./components/DeleteDuplicates";
import ConfirmDates from "./components/ConfirmDates";
import {Gallery} from "./components/Gallery"
import IdentifyFaces from "./components/IdentifyFaces";
import {GroupImages} from "./components/GroupImages";
import {CommunityGallery} from "./components/CommunityGallery"
import {BaseDemo} from "./components/FileUploader";
import {AuthenticationTitle} from "./components/authentication/Authenticate";
import {AuthenticationResultType, GetUserCommandOutput} from "@aws-sdk/client-cognito-identity-provider";


export default function App() {
    const [opened, {toggle}] = useDisclosure();
    const [authenticated, setAuthenticated] = useState(false); // State to track authentication status
    const [auth, setAuth] = React.useState<AuthenticationResultType>();
    const [user, setUser] = React.useState<GetUserCommandOutput>()
    const [currentComponent, setCurrentComponent] = React.useState<string>('gallery');
    const Component2: React.FC = () => <TextComponent/>

    const handleMenuItemClick = (component: string) => {
        setCurrentComponent(component);
    };

    const onSuccessfulSignOn = (authResults: AuthenticationResultType, userResults: GetUserCommandOutput) => {
        setAuthenticated(true)
        setAuth(authResults)
        setUser(userResults)
        console.log(authResults)
        console.log(userResults)
    }

    return (
        <MantineProvider>
            {authenticated ? (
                <div style={{display: 'flex', justifyContent: 'center', width: '100%', padding: '20px'}}>
                    {/*<div style={{ width: '75%', minWidth: '700px' }}>*/}
                    <AppShell
                        header={{height: 85}}
                        navbar={{width: '25%', breakpoint: 'sm', collapsed: {mobile: !opened}}}
                        padding="md"
                    >
                        <AppShell.Header>
                            <Flex justify="space-between" align="center"
                                  style={{width: '75%', margin: 'auto', padding: '10px 20px', height: '100%'}}>
                                <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm"/>
                                <Flex align="center">
                                    <img src="./logo.png" alt="Company Logo"
                                         style={{width: 52, marginRight: 20, marginTop: -15}}/>
                                    <div className="oswald-logo">SORT MY SHOEBOX</div>
                                </Flex>
                                <Flex align="center">
                                    <img src="./profile.png" alt="profile picture"
                                         style={{width: 45, marginRight: 15, marginTop: -5}}/>
                                    <div className="oswald-username">GRACE RAPER</div>
                                    <Flex style={{padding: '5px 15px'}}>
                                        {<IconChevronDown size="1.5rem" stroke={1.5}/>}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </AppShell.Header>
                        <AppShell.Navbar p="md" style={{padding: '20px', borderRight: '1px solid #ccc'}}>
                            <Flex style={{marginLeft: 'auto', marginRight: '10px'}}>
                                <LeftNavMenu onItemClick={handleMenuItemClick}/>
                            </Flex>
                        </AppShell.Navbar>
                        <AppShell.Main>
                            <Flex style={{width: '80%', marginRight: 'auto', marginLeft: '25px'}}>
                                {currentComponent === 'gallery' ?
                                    <Gallery userId={user?.Username || ""} auth={auth!}/>
                                : currentComponent === 'family tree' ?
                                    <FamilyTree userId={user?.Username || ""} auth={auth!}/>
                                : currentComponent === 'sort images' ?
                                    <SortOptionsMenu/>
                                : currentComponent === 'remove duplicates' ?
                                    <DeleteDuplicates userId={user?.Username || ""} auth={auth!}/>
                                : currentComponent === 'confirm dates' ?
                                    <ConfirmDates userId={user?.Username || ""} auth={auth!}/>
                                : currentComponent === 'identify faces' ?
                                    <IdentifyFaces userId={user?.Username || ""} auth={auth!}/>
                                : currentComponent === 'verify groupings' ?
                                    //<GroupPhotos/>
                                    <CommunityGallery userId={user?.Username || ""} auth={auth!}/>
                                : currentComponent === 'import' ?
                                    <BaseDemo userId={user?.Username || ""} auth={auth!} idToken={auth?.IdToken}/>
                                : currentComponent === 'group images' ?
                                    <GroupImages userId={user?.Username || ""} auth={auth!}/>
                                :
                                    <Component2/>}
                            </Flex>
                        </AppShell.Main>
                    </AppShell>
                </div>) : <AuthenticationTitle onSuccessfulSignIn={onSuccessfulSignOn} />}
        </MantineProvider>
    );
}