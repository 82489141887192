import React, { useState, useEffect } from 'react';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { Group, rem, Text, Image, Center, Flex, ActionIcon, RingProgress, Notification } from '@mantine/core';
import { IconUpload, IconPhoto, IconX, IconCheck } from '@tabler/icons-react';
import { Dropzone, DropzoneProps, FileWithPath, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { v4 as uuidv4 } from 'uuid';
import './FileUploader.css';
import ImageWithSkeleton from "@components/ImageWithSkeleton";

interface BaseDemoProps {
    userId: string;
    auth: any;
    idToken: string | undefined;
}

interface UploadingFile {
    file: FileWithPath;
    fileId: string;
    uploaded: boolean;
    processed: boolean;
}

export const BaseDemo: React.FC<BaseDemoProps> = ({ userId, idToken }, props: Partial<DropzoneProps>) => {
    const [files, setFiles] = useState<UploadingFile[]>([]);
    const [uploading, setUploading] = useState(false);
    const [ws, setWs] = useState<WebSocket | null>(null);

    useEffect(() => {
        const webSocket = new WebSocket(`https://98gmbtml6j.execute-api.us-west-2.amazonaws.com/production/?userId=${userId}`);

        webSocket.onopen = () => console.log('WebSocket connected');

        // todo: i think the error is originating in this method. Whenever I try to send a message via notifyImageUpload, this method has an error.
        //  I think this is because its reading the messages I send and there is no event.data.image_key to split
        webSocket.onmessage = (event) => {
            try {
                const message = JSON.parse(event.data);
                if (message.status === 'completed' && message.image_key) {
                    console.log('Message from WebSocket:', message);
                    const messageFileId = message.image_key.split('/')[1];
                    setFiles(prevFiles =>
                        prevFiles.map(file => file.fileId === messageFileId ? { ...file, processed: true } : file)
                    );
                } else if (message.status === 'tracking' && message.image_key) {
                    console.log('Message from WebSocket:', message);
                } else {
                    console.log('Message from WebSocket:', message);
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        };

        webSocket.onerror = (error) => {
            console.error('WebSocket error:', error);
            // Handle errors
        };

        webSocket.onclose = (event) => {
            console.log('WebSocket closed:', event);
            // Optionally, add retry logic to reconnect
        };

        setWs(webSocket);
        return () => webSocket.close();
    }, [userId]);


    const notifyImageUpload = (userId: string, imageKey: string) => {
        console.log(userId, imageKey)
        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({ action: 'imageUploaded', userId, imageKey }));
        } else {
            console.error('WebSocket connection is not open');
        }
    };

    const handleDrop = async (acceptedFiles: FileWithPath[]) => {
        const newFiles = acceptedFiles.map(file => {
            const fileId = uuidv4();
            return {
                file,
                fileId,
                uploaded: false,
                processed: false,
            };
        });
        setFiles(prevFiles => [...prevFiles, ...newFiles]);

        setUploading(true);

        if (!idToken) {
            console.error('No ID token available. User might not be authenticated.');
            return;
        }

        const s3Client = new S3Client({
            region: 'us-west-2',
            credentials: fromCognitoIdentityPool({
                client: new CognitoIdentityClient({ region: 'us-west-2' }),
                identityPoolId: 'us-west-2:d32fd088-c287-4f6c-8ca0-66e160c30a0f',
                logins: {
                    'cognito-idp.us-west-2.amazonaws.com/us-west-2_h68AazbCA': idToken,
                },
            }),
        });

        for (const newFile of newFiles) {
            try {
                const command = new PutObjectCommand({
                    Bucket: 'sort-my-shoebox-demo',
                    Key: `${userId}/${newFile.fileId}`,
                    Body: newFile.file,
                    ContentType: newFile.file.type,
                });

                try {
                    const response = await s3Client.send(command);
                    notifyImageUpload(userId, `${userId}/${newFile.fileId}`);
                    console.log('Successfully uploaded to S3:', response);
                } catch (err) {
                    console.error('Error uploading to S3:', err);
                }

                setFiles(prevFiles => {
                    const updatedFiles = [...prevFiles];
                    const fileIndex = updatedFiles.findIndex(f => f.file === newFile.file);
                    if (fileIndex !== -1) {
                        updatedFiles[fileIndex].uploaded = true;
                    }
                    return updatedFiles;
                });
            } catch (error) {
                console.error('Error uploading files:', error);
            }
        }

        setUploading(false);
    };

    const previews = files.map((uploadingFile, index) => {
        const { file, uploaded, processed } = uploadingFile;
        const imageUrl = URL.createObjectURL(file);
        return (
                    <div style={{
                        position: 'relative',
                        width: 'calc((100% - 12px) / 4)',
                        aspectRatio: 1 / 1,
                    }}>
                        <Image key={imageUrl} src={imageUrl} style={{ aspectRatio: 1 / 1 }} onLoad={() => URL.revokeObjectURL(imageUrl)} />
                        {uploaded && (
                            <ActionIcon color="teal" variant="filled" radius="xl" size="sm" style={{ position: 'absolute', top: rem(3), right: rem(3) }}>
                                <IconCheck size={rem(15)} />
                            </ActionIcon>
                        )}
                        {processed && (
                            <ActionIcon color="blue" variant="filled" radius="xl" size="sm" style={{ position: 'absolute', top: rem(3), left: rem(3) }}>
                                <IconCheck size={rem(15)} />
                            </ActionIcon>
                        )}
                    </div>



            // <div key={index} style={{ position: 'relative', width: 'calc((100% - 9px) / 4)', cursor: 'pointer', margin: '3px' }}>
            //     <Image key={index} src={imageUrl} style={{ aspectRatio: 1 / 1 }} onLoad={() => URL.revokeObjectURL(imageUrl)} />
            //     {uploaded && (
            //         <ActionIcon color="teal" variant="filled" radius="xl" size="sm" style={{ position: 'absolute', top: rem(3), right: rem(3) }}>
            //             <IconCheck size={rem(15)} />
            //         </ActionIcon>
            //     )}
            //     {processed && (
            //         <ActionIcon color="blue" variant="filled" radius="xl" size="sm" style={{ position: 'absolute', top: rem(3), left: rem(3) }}>
            //             <IconCheck size={rem(15)} />
            //         </ActionIcon>
            //     )}
            // </div>
        );
    });

    const uploadedCount = files.filter(file => file.uploaded).length;
    const processedCount = files.filter(file => file.processed).length;
    const totalCount = files.length;
    const uploadProgress = totalCount > 0 ? (uploadedCount / totalCount) * 100 : 0;

    // return (
    //     <div style={{ display: 'flex', flexDirection: 'column', width: '100vw' }}>
    //         <Center style={{ width: '100%' }}>
    //             <Dropzone
    //                 onDrop={handleDrop}
    //                 maxSize={10 * 1024 ** 2}
    //                 accept={IMAGE_MIME_TYPE}
    //                 {...props}
    //                 style={{ width: '100%', backgroundColor: 'var(--mantine-color-gray-light)' }}
    //             >
    //                 <Group justify="center" gap="xl" mih={220} style={{ pointerEvents: 'none', width: '100%' }}>
    //                     <Dropzone.Accept>
    //                         <IconUpload
    //                             style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
    //                             stroke={1.5}
    //                         />
    //                     </Dropzone.Accept>
    //                     <Dropzone.Idle>
    //                         <IconPhoto
    //                             style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)' }}
    //                             stroke={1.5}
    //                         />
    //                     </Dropzone.Idle>
    //                     <div>
    //                         <Text size="xl" inline>
    //                             Drag images here or click to select files
    //                         </Text>
    //                         <Text size="sm" c="dimmed" inline mt={7}>
    //                             Attach as many files as you like, each file should not exceed 10mb
    //                         </Text>
    //                     </div>
    //                 </Group>
    //             </Dropzone>
    //         </Center>
    //
    //         <Flex
    //             mt="md"
    //             direction="row"
    //             wrap="wrap"
    //             gap={{ base: '3px', sm: '3px' }}

// import React, { useState, useEffect } from 'react';
// import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
// import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
// import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
// import { Group, rem, Text, Image, Center, Flex, ActionIcon, RingProgress, Notification } from '@mantine/core';
// import { IconUpload, IconPhoto, IconX, IconCheck } from '@tabler/icons-react';
// import { Dropzone, DropzoneProps, FileWithPath, IMAGE_MIME_TYPE } from '@mantine/dropzone';
// import { v4 as uuidv4 } from 'uuid';
// import './FileUploader.css';
//
// interface BaseDemoProps {
//     userId: string;
//     auth: any;
//     idToken: string | undefined;
// }
//
// interface UploadingFile {
//     file: FileWithPath;
//     fileId: string;
//     uploaded: boolean;
//     processed: boolean;  // New field to track processing status
// }
//
//
//
// export const BaseDemo: React.FC<BaseDemoProps> = ({ userId, auth, idToken }, props: Partial<DropzoneProps>) => {
//     const [files, setFiles] = useState<UploadingFile[]>([]);
//     const [uploading, setUploading] = useState(false);
//     const [allUploaded, setAllUploaded] = useState(true);
//     const [ws, setWs] = useState<WebSocket | null>(null);  // State to manage WebSocket connection
//     const [isAuthenticated, setIsAuthenticated] = useState(false);
//
//
//     // WebSocket connection setup
//     useEffect(() => {
//
//         const webSocket = new WebSocket(`https://98gmbtml6j.execute-api.us-west-2.amazonaws.com/production/?userId=${userId}`);
//
//         webSocket.onopen = () => {
//             console.log('WebSocket connected');
//         };
//
//         webSocket.onmessage = (event) => {
//             const message = JSON.parse(event.data);
//             console.log('Message from WebSocket:', message);
//
//             const messageFileId = message.image_key.split('/')[1]; // Extract file ID from message
//             console.log(messageFileId);
//
//             // Update processed status based on fileId
//             if (messageFileId) {
//                 setFiles(prevFiles => {
//                     return prevFiles.map(file =>
//                         file.fileId === messageFileId ? { ...file, processed: true } : file
//                     );
//                 });
//             }
//         };
//
//         webSocket.onclose = () => {
//             console.log('WebSocket disconnected');
//         };
//
//         setWs(webSocket);
//
//         // Cleanup WebSocket on unmount
//         return () => {
//             webSocket.close();
//         };
//     }, []);
//
//
//     const notifyImageUpload = (userId: string, imageKey: string) => {
//         console.log("notifying image upload: ", userId, imageKey)
//         if (ws && ws.readyState === WebSocket.OPEN) {
//             ws.send(JSON.stringify({ "action": "image-uploaded", userId, imageKey }));
//         }
//     };
//
//     const handleDrop = async (acceptedFiles: FileWithPath[]) => {
//         const newFiles = acceptedFiles.map(file => {
//             const fileId = uuidv4();  // Generate unique identifier for both tracking and S3 key
//             return {
//                 file,
//                 fileId,   // Add fileId to track the UUID
//                 uploaded: false,
//                 processed: false,
//             };
//         });
//         setFiles(prevFiles => [...prevFiles, ...newFiles]);
//
//         setUploading(true);
//
//         if (!idToken) {
//             console.error('No ID token available. User might not be authenticated.');
//             return;
//         }
//
//         const s3Client = new S3Client({
//             region: 'us-west-2',
//             credentials: fromCognitoIdentityPool({
//                 client: new CognitoIdentityClient({ region: 'us-west-2' }),
//                 identityPoolId: 'us-west-2:d32fd088-c287-4f6c-8ca0-66e160c30a0f',
//                 logins: {
//                     'cognito-idp.us-west-2.amazonaws.com/us-west-2_h68AazbCA': idToken,
//                 },
//             }),
//         });
//
//         for (const newFile of newFiles) {
//             try {
//                 const command = new PutObjectCommand({
//                     Bucket: 'sort-my-shoebox-demo',
//                     Key: `${userId}/${newFile.fileId}`,  // Use the pre-generated fileId for the S3 key
//                     Body: newFile.file,
//                     ContentType: newFile.file.type,
//                 });
//
//                 try {
//                     const response = await s3Client.send(command);
//                     notifyImageUpload(userId, `${userId}/${newFile.fileId}`)
//                     console.log('Successfully uploaded to S3:', response);
//                 } catch (err) {
//                     console.error('Error uploading to S3:', err);
//                 }
//
//                 setFiles(prevFiles => {
//                     const updatedFiles = [...prevFiles];
//                     const fileIndex = updatedFiles.findIndex(f => f.file === newFile.file);
//                     if (fileIndex !== -1) {
//                         updatedFiles[fileIndex].uploaded = true;
//                     }
//                     return updatedFiles;
//                 });
//             } catch (error) {
//                 console.error('Error uploading files:', error);
//             }
//         }
//
//         setUploading(false);
//     };
//
//     const previews = files.map((uploadingFile, index) => {
//         const { file, uploaded, processed } = uploadingFile;
//         const imageUrl = URL.createObjectURL(file);
//         return (
//             <div key={index} style={{ position: 'relative', width: 'calc((100% - 9px) / 4)', cursor: 'pointer', margin: '3px' }}>
//                 <Image src={imageUrl} style={{ aspectRatio: 1 / 1 }} onLoad={() => URL.revokeObjectURL(imageUrl)} />
//                 {uploaded && (
//                     <ActionIcon color="teal" variant="filled" radius="xl" size="sm" style={{ position: 'absolute', top: rem(3), right: rem(3) }}>
//                         <IconCheck size={rem(15)} />
//                     </ActionIcon>
//                 )}
//                 {processed && (
//                     <ActionIcon color="blue" variant="filled" radius="xl" size="sm" style={{ position: 'absolute', top: rem(3), left: rem(3) }}>
//                         <IconCheck size={rem(15)} />  {/* Use a different icon or indicator for processed status */}
//                     </ActionIcon>
//                 )}
//             </div>
//         );
//     });
//
//     const uploadedCount = files.filter(file => file.uploaded).length;
//     const processedCount = files.filter(file => file.processed).length;
//     const totalCount = files.length;
//     const uploadProgress = totalCount > 0 ? (uploadedCount / totalCount) * 100 : 0;
//
    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100vw' }}>
            <Center style={{ width: '100%' }}>
                <Dropzone
                    onDrop={handleDrop}
                    maxSize={10 * 1024 ** 2}
                    accept={IMAGE_MIME_TYPE}
                    {...props}
                    style={{ width: '100%', backgroundColor: 'var(--mantine-color-gray-light)' }}
                >
                    <Group justify="center" gap="xl" mih={220} style={{ pointerEvents: 'none', width: '100%' }}>
                        <Dropzone.Accept>
                            <IconUpload
                                style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
                                stroke={1.5}
                            />
                        </Dropzone.Accept>
                        <Dropzone.Idle>
                            <IconPhoto
                                style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)' }}
                                stroke={1.5}
                            />
                        </Dropzone.Idle>
                        <div>
                            <Text size="xl" inline>
                                Drag images here or click to select files
                            </Text>
                            <Text size="sm" c="dimmed" inline mt={7}>
                                Attach as many files as you like, each file should not exceed 10mb
                            </Text>
                        </div>
                    </Group>
                </Dropzone>
            </Center>

            <Flex
                mt="md"
                direction="row"
                wrap="wrap"
                gap={{ base: '3px', sm: '3px' }}
                justify={{ sm: '3px' }}
            >
                {previews}
            </Flex>

            <Notification
                icon={
                    totalCount === processedCount ? (
                        <RingProgress ml={'sm'} mr={'sm'}
                                      sections={[{ value: 100, color: 'teal' }]}
                                      label={
                                          <Center>
                                              <ActionIcon color="teal" variant="light" radius="xl" size="xl">
                                                  <IconCheck style={{ width: rem(20), height: rem(20) }} />
                                              </ActionIcon>
                                          </Center>
                                      }
                                      size={50}
                                      thickness={4}
                        />
                    ) : (
                        <RingProgress ml={'sm'} mr={'sm'}
                                      sections={[{ value: uploadProgress, color: 'blue' }]}
                                      label={
                                          <Text c="blue" fw={700} ta="center" size="xl">
                                              {uploadProgress.toFixed(0)}%
                                          </Text>
                                      }
                                      size={50}
                                      thickness={4}
                        />
                    )
                }
                withBorder
                color={'none'}
                radius="lg"
                title={uploading ? 'Uploading...' : totalCount === processedCount ? 'All files uploaded' : 'Uploading files...'}
                // title={uploading ? 'Uploading...' : totalCount === processedCount ? 'All files processed' : 'Uploading files...'}
            >
                <Text>{uploadedCount}/{totalCount} files uploaded</Text>
                {/*<Text>{uploadedCount}/{totalCount} files uploaded, {processedCount}/{totalCount} files processed</Text>*/}
            </Notification>
        </div>
    );
};