import React, {useState, useEffect} from 'react';
import {
    Blockquote,
    Divider,
    Flex,
    Group,
    Image,
    Paper,
    Title,
    Button,
    Modal,
    Text,
    Skeleton,
    PaperProps,
    Loader
} from '@mantine/core';
import {DateInput} from '@mantine/dates';
import '@mantine/dates/styles.css';
import {IconInfoCircle, IconCheck} from "@tabler/icons-react";
import axios from 'axios';
import {AuthenticationResultType} from "@aws-sdk/client-cognito-identity-provider";
import {ImageData, SuggestionNewEvent} from './GroupInterfaces';
import ImageWithSkeleton from "./ImageWithSkeleton";

interface DateSuggestion {
    event_key: string;
    estimated_date: string;
    event_images: ImageData[];
}

interface FamilyProps extends PaperProps {
    userId: string,
    auth: AuthenticationResultType,
}

export default function ConfirmDates({userId, auth}: FamilyProps) {
    const [dateGroups, setDateGroups] = useState<DateSuggestion[]>([]);
    const [adjustingDateId, setAdjustingDateId] = useState<string | null>(null);
    const [adjustingDate, setAdjustingDate] = useState<Date | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/event/dates";
            const id_token = auth.IdToken;

            const response = await axios.get(apiUrl, {
                params: {
                    sub: userId,
                },
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });

            console.log(response);

            const dateSuggestions: DateSuggestion[] = JSON.parse(response.data.body).map((suggestion: any) => ({
                event_key: suggestion.event_key,
                estimated_date: suggestion.estimated_datetime.split('T')[0],
                event_images: suggestion.event_images
            }));

            setDateGroups(dateSuggestions);
            setIsLoading(false)
            console.log('Parsed date groups:', dateSuggestions);
        } catch (error) {
            console.error('Error fetching date predictions:', error);
            // Handle error
        }
    };

    const formatDateKey = (dateString: string) => {
        const date = new Date(dateString);
        const options = {month: 'long', day: 'numeric', year: 'numeric'} as const;
        return date.toLocaleDateString('en-US', options);
    };

    const handleConfirmDate = async (suggestion: DateSuggestion, datetime: string) => {
        setDateGroups(prevGroups => prevGroups.filter(group => group.event_key !== suggestion.event_key));
        try {
            const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/event/dates";
            const id_token = auth.IdToken;

            const response = await axios.patch(apiUrl,
                {
                    uid: userId,
                    event_key: suggestion.event_key,
                    event_images: suggestion.event_images,
                    datetime: datetime
                },
                {
                    headers: {
                        'Authorization': `Bearer ${id_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            console.log('Confirmed Date:', response.data);
        } catch (error) {
            console.error('Error confirming date:', error);
        }
    };

    const handleAdjustDate = (suggestion: DateSuggestion) => {
        const selectedGroup = dateGroups.find(group => group.event_key === suggestion.event_key);
        if (selectedGroup) {
            setAdjustingDateId(suggestion.event_key);
            setAdjustingDate(new Date(selectedGroup.estimated_date));
        }
    };

    const handleModalClose = () => {
        setAdjustingDateId(null);
        setAdjustingDate(undefined);
    };

    const handleDateAdjustment = async () => {
        try {
            if (adjustingDateId && adjustingDate) {
                await handleConfirmDate(
                    dateGroups.find(group => group.event_key === adjustingDateId)!,
                    adjustingDate.toISOString().split('T')[0]
                );
                handleModalClose();
            }
        } catch (error) {
            console.error('Error adjusting date:', error);
        }
    };

    const DateGroupsSkeleton = () => {
        return (
            <div>
                {[...Array(3)].map((_, index) => (
                    <Paper shadow="sm" radius="lg" withBorder p="xl" key={index} mt={index > 0 ? "lg" : undefined}
                           style={{backgroundColor: '#f0f0f0'}}>
                        <Skeleton height={28} width="40%" radius="xl"/>
                        <Skeleton height={1} width="100%" mt="sm"/>
                        <Flex
                            mt="md"
                            direction="row"
                            wrap="wrap"
                            gap={{base: '3px', sm: '3px'}}
                            justify={{sm: '3px'}}
                        >
                            {[...Array(5)].map((_, imgIndex) => (
                                <Skeleton
                                    key={imgIndex}
                                    width="calc((100% - 12px) / 5)"
                                    height={0}
                                    style={{paddingBottom: 'calc((100% - 12px) / 5)'}}
                                    radius="sm"
                                />
                            ))}
                        </Flex>
                        <Group justify="flex-end" gap="sm" mt="lg">
                            <Skeleton height={36} width={80} radius="md"/>
                            <Skeleton height={36} width={120} radius="md"/>
                        </Group>
                    </Paper>
                ))}
            </div>
        );
    };

    return (
        <Flex direction='column'>
            <Title order={1}>Confirm Dates</Title>
            <Divider mt='xs'/>
            <Blockquote color="blue" icon={<IconInfoCircle/>} mt='lg' mb='lg'>
                Confirming dates ensures accurate chronological organization of your photos, allowing you to relive your
                memories in the order they occurred. This process enhances the overall coherence of your photo
                collection, making it easier to navigate and reminisce about significant moments.
            </Blockquote>

            {isLoading ? (
                <DateGroupsSkeleton/>
            ) : (
                <div>
                    {dateGroups.map((suggestion, index) => (
                        <Paper shadow="sm" radius="lg" withBorder p="xl" key={suggestion.event_key}
                               mt={index > 0 ? "lg" : undefined}
                               style={{backgroundColor: '#f0f0f0'}}>
                            <Title
                                order={2}>{"Estimated Date: " + formatDateKey(suggestion.estimated_date) || 'Unknown'}</Title>
                            <Divider mt='sm'/>
                            <Flex
                                mt="md"
                                direction="row"
                                wrap="wrap"
                                gap={{base: '3px', sm: '3px'}}
                                justify={{sm: '3px'}}
                            >
                                {suggestion.event_images.map((image) => (
                                    <div style={{
                                        position: 'relative',
                                        width: 'calc((100% - 20px) / 6)',
                                        aspectRatio: 1 / 1,
                                    }}>
                                        <ImageWithSkeleton
                                            key={image.key}
                                            src={image.thumbnail_url}
                                            alt={`Photo taken on ${suggestion.estimated_date}`}
                                            onClick={() => {
                                            }}
                                        />
                                    </div>
                                ))}
                            </Flex>
                            <Group justify="flex-end" gap="sm" mt="lg">
                                <Button color="gray" variant="outline"
                                        onClick={() => handleAdjustDate(suggestion)}>Adjust</Button>
                                <Button color="blue" disabled={!suggestion.estimated_date}
                                        onClick={() => handleConfirmDate(suggestion, suggestion.estimated_date)}>
                                    Confirm Date
                                </Button>
                            </Group>
                        </Paper>
                    ))}
                </div>
            )}
            {/* Modal for adjusting date */}
            <Modal
                opened={!!adjustingDateId}
                onClose={handleModalClose}
                title="Adjust Date"
                size="md"
                padding="md"
            >
                <Text>
                    Enter the adjusted date for the selected group:
                </Text>
                <DateInput
                    value={adjustingDate}
                    onChange={(value) => setAdjustingDate(value as Date)}
                    placeholder="Select date"
                    style={{marginTop: 10}}
                />
                <Flex justify={"flex-end"}>
                    <Button color="blue" style={{marginTop: 20}}
                            onClick={handleDateAdjustment}
                    >
                        Confirm Date
                    </Button>
                </Flex>
            </Modal>
        </Flex>
    );
}