import React, { useState, useEffect } from 'react';
import {
    Blockquote,
    Title,
    Flex,
    Paper,
    Button,
    Image,
    Divider,
    Group,
    Skeleton,
    PaperProps, Loader, Anchor,
} from '@mantine/core';
import {IconArrowRight, IconInfoCircle} from "@tabler/icons-react";
import axios from 'axios';
import { AuthenticationResultType } from "@aws-sdk/client-cognito-identity-provider";
import { FaceData } from "./FaceDataInterface";
import AddFacesModal from './AddFacesToFamilyTreeModal';
import {AuthStateOptions} from "@authentication/Authenticate";

export interface NameAndSuggested {
    key: string,
    name: string | null;
    faces: FaceData[]; // URLs of duplicate images
}

interface IdentifyFacesProps extends PaperProps {
    userId: string,
    auth: AuthenticationResultType,
}

export function IdentifyFaces({ userId, auth }: IdentifyFacesProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [okToFetchMore, setOkayToFetchMore] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [updatingDatabase, setUpdatingDatabase] = useState(false)
    const [namesAndFacesToId, setNamesAndFacesToId] = useState<NameAndSuggested[]>([]);

    // Track the selected person and modal visibility
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState<NameAndSuggested | null>(null);

    useEffect(() => {
        fetchSuggestions();
    }, []);

    useEffect(() => {
        console.log("ok:", okToFetchMore, "updating:", updatingDatabase)
        if (namesAndFacesToId.length <= 3 && okToFetchMore && !updatingDatabase && !isLoading && !isLoadingMore) {
            fetchMoreSuggestions();
        }
    }, [namesAndFacesToId, okToFetchMore, updatingDatabase]);

    const fetchSuggestions = async () => {
        setIsLoading(true);
        try {
            const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/faces";
            const id_token = auth.IdToken;

            const response = await axios.get(apiUrl, {
                params: { sub: userId },
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });

            const namesAndFaces: NameAndSuggested[] = typeof response.data.body === 'string'
                ? JSON.parse(response.data.body)
                : response.data.body;

            if (namesAndFaces.length > 3) {
                setOkayToFetchMore(true);
            }

            setNamesAndFacesToId(namesAndFaces);
        } catch (error) {
            console.error('Error fetching family members:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchMoreSuggestions = async () => {
        setIsLoadingMore(true);
        try {
            const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/faces";
            const id_token = auth.IdToken;

            const response = await axios.get(apiUrl, {
                params: { sub: userId },
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });

            const newNamesAndFaces: NameAndSuggested[] = typeof response.data.body === 'string'
                ? JSON.parse(response.data.body)
                : response.data.body;

            // Filter out suggestions with conflicting keys
            const existingKeys = new Set(namesAndFacesToId.map(item => item.key));
            const filteredNewSuggestions = newNamesAndFaces.filter(item => !existingKeys.has(item.key));

            setNamesAndFacesToId(prevState => [...prevState, ...filteredNewSuggestions]);
        } catch (error) {
            console.error('Error fetching more suggestions:', error);
        } finally {
            setOkayToFetchMore(false);
            setIsLoadingMore(false);
        }
    };

    const handleAddPersonToFamilyTree = (person: NameAndSuggested) => {
        setSelectedPerson(person);
        setModalOpen(true);
    };

    const handleAddFamilyMember = async (person: NameAndSuggested, firstName: string, lastName: string, dateOfBirth: string) => {
        setUpdatingDatabase(true);
        setNamesAndFacesToId(prevState => prevState.filter(p => p.key !== person.key));
        try {
            const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/person";
            // todo: right now, the newPerson backend is responsible for attaching these photos but it might be best
            //  to break this workflow into two parts, the creation of the new person, then the naming of the faces.
            const response = await axios.post(apiUrl,
                {
                    uid: userId,
                    key: person.key,
                    first_name: firstName,
                    last_name: lastName,
                    dob: dateOfBirth,
                    faces: person.faces.map(face => ({
                        key: face.key,
                        rekognition_id: face.rekognition_id // assuming rekognitionId is part of FaceData
                    }))
                },
                {
                    headers: {
                        'Authorization': `Bearer ${auth.IdToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log('Added family member:', response.data);
        } catch (error) {
            console.error('Error adding family member:', error);
        } finally {
            setModalOpen(false);
            setSelectedPerson(null);
            setUpdatingDatabase(false);
            setOkayToFetchMore(true);
        }
    };

    const handleConfirmFacesForPerson = async (person: NameAndSuggested) => {
        setUpdatingDatabase(true);
        setNamesAndFacesToId(prevState => prevState.filter(p => p.key !== person.key));
        try {
            const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/faces";
            const response = await axios.patch(apiUrl,
                {
                    uid: userId,
                    key: person.key,
                    faces: person.faces.map(face => ({
                        key: face.key,
                        rekognition_id: face.rekognition_id // assuming rekognitionId is part of FaceData
                    }))
                },
                {
                    headers: {
                        'Authorization': `Bearer ${auth.IdToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            console.log('Confirmed Faces:', response.data);
        } catch (error) {
            console.error('Error confirming faces:', error);
        } finally {
            setUpdatingDatabase(false);
            setOkayToFetchMore(true);
        }
    };

    const handleUntrackPerson = async (person: NameAndSuggested) => {
        setUpdatingDatabase(true);
        setNamesAndFacesToId(prevState => prevState.filter(p => p.key !== person.key));
        try {
            const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/person/untrack";
            // todo: not really a post... more of a patch
            const response = await axios.post(apiUrl,
                {
                    uid: userId,
                    tag: "untrack",
                    key: person.key,
                    faces: person.faces.map(face => ({
                        key: face.key,
                        rekognition_id: face.rekognition_id // assuming rekognitionId is part of FaceData
                    }))
                },
                {
                    headers: {
                        'Authorization': `Bearer ${auth.IdToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log('Untracked faces:', response.data);
        } catch (error) {
            console.error('Error untracking faces:', error);
        } finally {
            setUpdatingDatabase(false);
            setOkayToFetchMore(true);
        }
    };

    const handleAskAgainLater = async (person:NameAndSuggested) => {
        setUpdatingDatabase(true);
        setNamesAndFacesToId(prevState => prevState.filter(p => p.key !== person.key));
        try {
            const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/person/ask-later";
            // todo: not really a post... more of a patch
            const response = await axios.post(apiUrl,
                {
                    uid: userId,
                    tag: "ask-again",
                    key: person.key,
                    faces: person.faces.map(face => ({
                        key: face.key,
                        rekognition_id: face.rekognition_id // assuming rekognitionId is part of FaceData
                    }))
                },
                {
                    headers: {
                        'Authorization': `Bearer ${auth.IdToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log('Marked faces as ask again:', response.data);
        } catch (error) {
            console.error('Error marking faces as ask again:', error);
        } finally {
            setUpdatingDatabase(false);
            setOkayToFetchMore(true);
        }
    };

    const renderSkeletonSuggestions = () => (
        Array(4).fill(null).map((_, index) => (
            <Paper shadow="sm" radius="lg" withBorder p="xl" key={index} mt={index > 0 ? "lg" : undefined} style={{ backgroundColor: '#f0f0f0' }}>
            <Skeleton height={30} width="40%" mb="lg" />
            <Flex
                mt="md"
                direction="row"
                wrap="wrap"
                gap={{ base: '1px', sm: '1px' }}
                justify="flex-start"
            >
                {[...Array(16)].map((_, i) => (
                    <Skeleton key={`face-skeleton-${i}`} width="calc((100% - 23px) / 8)" height={0} style={{ paddingBottom: 'calc((100% - 23px) / 8)', margin: '1px' }} />
                ))}
            </Flex>
            <Flex justify="space-between" gap="sm" mt="lg">
                <Skeleton height={36} width={150} />
                <Flex gap="sm">
                    <Skeleton height={36} width={120} />
                    <Skeleton height={36} width={120} />
                </Flex>
            </Flex>
        </Paper>
    )));

    return (
        <Flex direction='column'>
            <Title order={1}>Identify Faces</Title>
            <Divider mt='xs' />
            <Blockquote color="blue" icon={<IconInfoCircle />} mt='lg' mb='lg'>
                Identifying people in photos is crucial as it helps organize images accurately, enabling easy retrieval and browsing. Moreover, it enhances the AI's ability to predict ages, facilitating the estimation of when the photos were taken based on known birthdates.
            </Blockquote>
            {isLoading ? renderSkeletonSuggestions() : namesAndFacesToId.map((person, index) => (
                <Paper shadow="sm" radius="lg" withBorder p="xl" key={person.key} mt={index > 0 ? "lg" : undefined} style={{ backgroundColor: '#f0f0f0' }}>
                    <Title order={3} mb={'lg'}>{person.name ? `Is this ${person.name}?` : "Do you know this person?"}</Title>
                    <Flex
                        mt="md"
                        direction="row"
                        wrap="wrap"
                        gap={{ base: '1px', sm: '1px' }}
                        justify="flex-start"
                    >
                        {person.faces.map((face) => (
                            <div key={face.key} style={{ position: 'relative', width: 'calc((100% - 23px) / 8)', cursor: 'pointer', margin: '1px' }}>
                                <Image src={face.url} alt={`face_thumbnail`} fallbackSrc="https://placehold.co/400x400?text=Placeholder" style={{
                                    aspectRatio: 1 / 1,
                                    cursor: 'pointer',
                                    fit: 'fill',
                                }}
                                />
                            </div>
                        ))}
                    </Flex>

                        {person.name ? (
                            <Flex justify={{ sm: 'right' }} gap="sm" mt="lg">
                                <Button color="gray" variant="outline">None of These are {person.name}</Button>
                                <Button color="blue" onClick={() => handleConfirmFacesForPerson(person)}>Confirm</Button>
                            </Flex>
                        ) : (
                            <Flex justify="space-between" gap="sm" mt="lg">
                                <Button color="gray" variant="outline" onClick={() => handleUntrackPerson(person)}>Don't Track these Faces</Button>

                                <Flex gap="sm">
                                <Button color="gray" variant="outline" onClick={() => handleAskAgainLater(person)}>Ask Again Later</Button>
                                <Button color="blue" onClick={() => handleAddPersonToFamilyTree(person)}>Yes, Add to Family Tree</Button>
                                </Flex>
                            </Flex>
                        )}

                </Paper>
            ))}
            {/*{isLoadingMore && (*/}
            {/*    <Paper shadow="sm" radius="lg" withBorder p="xl" mt="lg" style={{ backgroundColor: '#f0f0f0' }}>*/}
            {/*        <Skeleton height={30} width="60%" mb="lg" />*/}
            {/*        <Skeleton height={100} width="100%" mb="lg" />*/}
            {/*        <Flex justify="space-between" gap="sm" mt="lg">*/}
            {/*            <Skeleton height={36} width={150} />*/}
            {/*            <Flex gap="sm">*/}
            {/*                <Skeleton height={36} width={120} />*/}
            {/*                <Skeleton height={36} width={120} />*/}
            {/*            </Flex>*/}
            {/*        </Flex>*/}
            {/*    </Paper>*/}
            {/*)}*/}
            {selectedPerson && modalOpen && (
                <AddFacesModal
                    onClose={() => setModalOpen(false)}
                    onAddFamilyMember={handleAddFamilyMember}
                    person={selectedPerson}
                    // faces={selectedPerson.faces.slice(0, 1)} // Show the first 4 faces
                />
            )}
        </Flex>
    );
}

export default IdentifyFaces;
