import React, { useState } from 'react';
import { NavLink, Box, Text } from '@mantine/core';
import { IconPhoto, IconFaceId, IconTree, IconPhotoX, IconUpload, IconSeeding, IconBinaryTree, IconProgressCheck, IconCalendarPlus, IconChartDots3, IconFingerprint, IconActivity, IconChevronRight, IconChevronDown } from '@tabler/icons-react';

interface MenuItem {
    icon: React.ElementType;
    label: string;
    description?: string;
    rightSection?: React.ReactNode;
    rightSectionExpanded?: React.ReactNode;
    children?: MenuItem[];
}

interface LeftNavMenuProps {
    onItemClick: (component: string) => void; // Define onItemClick prop
}

const data: MenuItem[] = [
    {
        icon: IconPhoto,
        label: 'Gallery',
    },
    {
        icon: IconPhotoX,
        label: 'Remove Duplicates',
    },
    {
        icon: IconSeeding,
        label: 'Family Tree',
    },
    {
        icon: IconFaceId,
        label: 'Identify Faces',
    },
    {
        icon: IconBinaryTree,
        label: 'Group Images',
    },
    {
        icon: IconCalendarPlus,
        label: 'Confirm Dates',
    },
    // {
    //     icon: IconProgressCheck,
    //     label: 'Sort Images',
    //     description: 'Organize photos with AI',
    //     rightSection: <IconChevronRight size="1rem" stroke={1.5} />,
    //     rightSectionExpanded: <IconChevronDown size="1rem" stroke={1.5} />,
    //     children: [
    //         {
    //             icon: IconPhotoX,
    //             label: 'Remove Duplicates',
    //         },
    //         {
    //             icon: IconBinaryTree,
    //             label: 'Verify Groupings',
    //         },
    //         {
    //             icon: IconFaceId,
    //             label: 'Identify Faces',
    //         },
    //         {
    //             icon: IconCalendarPlus,
    //             label: 'Confirm Dates',
    //         },
    //     ],
    // },
];

export function LeftNavMenu({ onItemClick }: LeftNavMenuProps) {
    const [active, setActive] = useState<number | null>(null);

    const handleItemClick = (index: number) => {
        setActive(index === active ? null : index); // Toggle active state
    };

    const renderItem = (item: MenuItem, index: number) => (
        <Box key={item.label}>
            <NavLink
                active={index === active}
                label={item.label}
                description={item.description}
                rightSection={index === active ? item.rightSectionExpanded : item.rightSection}
                leftSection={<item.icon size="2rem" stroke={1.5} />}
                onClick={() => {
                    setActive(index === active ? null : index); // Toggle active state
                    onItemClick(item.label.toLowerCase());
                }}
                variant="light"
            />
            {active === index && item.children && (
                <Box ml={16}>
                    {item.children.map((child, childIndex) => (
                        <NavLink
                            key={child.label}
                            label={child.label}
                            description={child.description}
                            rightSection={child.rightSection}
                            leftSection={<child.icon size="1rem" stroke={1.5} />}
                            onClick={() => onItemClick(child.label.toLowerCase())}
                            variant="light"
                        />
                    ))}
                </Box>
            )}
        </Box>
    );

    return <Box w="100%">{data.map(renderItem)}
        <NavLink
        key={"Import"}
        href="#required-for-focus"
        label={"Import"}
        description={''}
        rightSection={''}
        leftSection={<IconUpload size="2rem" stroke={1.5} />}
        onClick={() => onItemClick("Import".toLowerCase())}
        variant="light"
    />
    </Box>;
}
