import {Button, Flex, Image, Paper, Title, Group} from "@mantine/core";
import {IconArrowRight} from "@tabler/icons-react";
import {Carousel} from '@mantine/carousel';
import {SuggestionAddImageToEvent} from './GroupInterfaces';

interface AddToEventProps {
    suggestion: SuggestionAddImageToEvent,
    onSubmit: (shouldAdd: boolean) => void;
}

export function AddToEvent({suggestion, onSubmit}: AddToEventProps) {
    return (
        <Paper shadow="sm" radius="lg" withBorder p="xl" key={suggestion.key}
               style={{marginBottom: '20px', padding: '10px', backgroundColor: '#f0f0f0'}}>
            <Title order={3} mb={'lg'}>Does this image belong in the group?</Title>
            <Group
                mt="md"
                align="center"
                justify="center"
            >
                {/* Suggested Image */}
                <div style={{width: 'calc((100% - 12px) / 5)', aspectRatio: 1 / 1}}>
                    <Image
                        src={suggestion.image.thumbnail_url}
                        alt="Suggested Thumbnail"
                        style={{
                            aspectRatio: 1 / 1,
                            cursor: 'pointer',
                        }}
                    />
                </div>

                {/* Divider/Arrow */}
                <div style={{padding: 'calc((((100% - 12px) / 5) - 100px) / 2)', textAlign: 'center', fontSize: '2rem', color: '#888'}}>
                    <IconArrowRight size={70}/>
                </div>


                {suggestion.event.images.length <= 3 ? (
                    // Render images directly if there are 3 or fewer
                    <div style={{width: `calc(((100% - 12px) / 5 ) * ${suggestion.event.images.length})`}}>
                        <Flex direction='row' gap='3px'>
                            {suggestion.event.images.map(image => (
                                <Image
                                    src={image.thumbnail_url}
                                    alt="Group Thumbnail"
                                    style={{
                                        width: `calc((100%) / ${suggestion.event.images.length})`,
                                        aspectRatio: 1 / 1,
                                        cursor: 'pointer',
                                    }}
                                />
                            ))}
                        </Flex>
                    </div>
                ) : (
                    <div style={{width: 'calc(((100% - 12px) / 5 ) * 3)'}}>
                        <Paper shadow="none" radius="md" style={{backgroundColor: '#f0f0f0'}}>
                            <Carousel
                                withIndicators
                                slideGap="3px"
                                slideSize="33.34%"
                                loop
                                align="start"
                                slidesToScroll={1}
                                styles={{
                                    slide: {
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }
                                }}
                            >
                                {suggestion.event.images.map(image => (
                                    <Carousel.Slide key={image.key}>
                                        <div style={{
                                            width: '100%',
                                            aspectRatio: 1 / 1,
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}>
                                            <Image
                                                src={image.thumbnail_url}
                                                alt="Group Thumbnail"
                                                style={{
                                                    width: '100%',
                                                    aspectRatio: 1 / 1,
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        </div>
                                    </Carousel.Slide>
                                ))}
                            </Carousel>
                        </Paper>
                    </div>
                )
                }
            </Group>
            <Flex justify={{sm: 'right'}} gap="sm" mt="md">
                <Button onClick={() => onSubmit(false)} color="gray" variant="outline">No</Button>
                <Button onClick={() => onSubmit(true)} color="blue">Yes, Add to Group</Button>
            </Flex>
        </Paper>
    );
}