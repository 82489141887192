import React, { useState } from 'react';
import { Modal, TextInput, Button, Fieldset, Flex, Image } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { FaceData } from './FaceDataInterface';
import { NameAndSuggested} from './IdentifyFaces';
interface AddFacesToFamilyTreeModalProps {
    onClose: () => void;
    onAddFamilyMember: (person: NameAndSuggested, firstName: string, lastName: string, dateOfBirth: string) => void;
    person: NameAndSuggested;
}

const AddFacesModal: React.FC<AddFacesToFamilyTreeModalProps> = ({onClose, onAddFamilyMember, person }) => {
    const [newMember, setNewMember] = useState({ firstName: '', lastName: '', dateOfBirth: '' });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setNewMember((prevMember) => ({
            ...prevMember,
            [name]: value,
        }));
    };

    const handleDateChange = (value: Date | null) => {
        setNewMember((prevMember) => ({
            ...prevMember,
            dateOfBirth: value ? value.toLocaleDateString() : '', // Convert date to MM/DD/YYYY format
        }));
    };

    const handleSubmit = () => {
        onAddFamilyMember(person, newMember.firstName, newMember.lastName, newMember.dateOfBirth);
        onClose();
    };

    return (
        <Modal opened onClose={onClose} size="lg" title="Add New Family Member" >
            {/*<Fieldset legend="Select Face">*/}
            <Flex
                mt="md"
                direction="row"
                wrap="wrap"
                gap={{ base: '1px', sm: '1px' }}
                justify="center"
            >
                {person.faces.slice(0, 1).map((face) => (
                    <div
                        key={face.key}
                        style={{
                            position: 'relative',
                            width: '30%',
                            cursor: 'pointer',
                            margin: '1px'
                        }}
                    >
                        <Image
                            src={face.url}
                            alt={`face_thumbnail`}
                            fallbackSrc="https://placehold.co/400x400?text=Placeholder"
                            style={{
                                aspectRatio: 1 / 1,
                                cursor: 'pointer',
                                fit: 'fill',
                                borderRadius: '45px', // Rounded corners
                                border: '1px solid grey', // Grey border
                            }}
                        />
                    </div>
                ))}
            </Flex>
            {/*</Fieldset>*/}
            <Fieldset mt="md" legend="Required Information">
                <TextInput
                    label="First Name"
                    placeholder="Enter first name"
                    value={newMember.firstName}
                    onChange={handleInputChange}
                    name="firstName"
                    required
                    style={{ marginBottom: '16px' }}
                />
                <TextInput
                    label="Last Name"
                    placeholder="Enter last name"
                    value={newMember.lastName}
                    onChange={handleInputChange}
                    name="lastName"
                    required
                    style={{ marginBottom: '16px' }}
                />
                <DateInput
                    valueFormat="MM/DD/YYYY"
                    label="Date of Birth"
                    placeholder="MM/DD/YYYY"
                    value={newMember.dateOfBirth ? new Date(newMember.dateOfBirth) : null}
                    onChange={handleDateChange}
                    required
                    style={{ marginBottom: '16px' }}
                />
            </Fieldset>
            <Flex justify={{ sm: 'right' }}>
                <Button mt="lg" variant="outline" color="blue" onClick={handleSubmit}>
                    Add Member
                </Button>
            </Flex>
        </Modal>
    );
};

export default AddFacesModal;