import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Blockquote, Divider, Flex, Image, Loader, Paper, PaperProps, Skeleton, Title } from '@mantine/core';
import ImageInspector from './ImageInspector';
import ImageModal from './ImageModal';
import { AuthenticationResultType } from "@aws-sdk/client-cognito-identity-provider";
import { IconInfoCircle } from "@tabler/icons-react";
import ImageWithSkeleton from "./ImageWithSkeleton";
interface ImageData {
    location: string;
    key: string;
    datetime: string | null;
    event_key: string | null;
}

interface GalleryProps extends PaperProps {
    userId: string;
    auth: AuthenticationResultType;
}

export function Gallery({ userId, auth }: GalleryProps) {
    const [images, setImages] = useState<ImageData[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [hideUnknown, setHideUnknown] = useState(true);
    const [fullscreenImage, setFullscreenImage] = useState<string | null>(null);
    const [page, setPage] = useState(1);
    const [limit] = useState(20);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        fetchGalleryImages(page);
    }, [hideUnknown, page]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 500 && !loading && hasMore) {
                loadMoreImages();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loading, hasMore]);

    const fetchGalleryImages = async (page: number) => {
        setLoading(true);
        try {
            const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/gallery";
            const id_token = auth.IdToken;

            const response = await axios.get(apiUrl, {
                params: {
                    hideUnknown: hideUnknown.toString(),
                    sub: userId,
                    page: page.toString(),
                    limit: limit.toString()
                },
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });

            const newImages: ImageData[] = typeof response.data.body === 'string'
                ? JSON.parse(response.data.body)
                : response.data.body;

            setImages((prevImages) => [...prevImages, ...newImages]);
            setHasMore(newImages.length === limit);
        } catch (error) {
            console.error('Error fetching images:', error);
            if (axios.isAxiosError(error)) {
                console.error('Axios error:', error.message);
            } else {
                console.error('Unexpected error:', error);
            }
        } finally {
            setLoading(false);
        }
    }

    const loadMoreImages = () => {
        if (hasMore && !loading) {
            setPage(prevPage => prevPage + 1);
        }
    };

    const handleFullscreenImage = (imageUrl: string) => {
        setFullscreenImage(imageUrl);
    };

    const handleCloseFullscreen = () => {
        setFullscreenImage(null);
    };

    const formatDateKey = (dateString: string) => {
        const date = new Date(dateString);
        const options = { month: 'long', day: 'numeric', year: 'numeric' } as const;
        return date.toLocaleDateString('en-US', options);
    };

    const groupImages = (images: ImageData[]) => {
        let currentEventKey: string | null = null;
        let currentEventIndex = 0;
        let noEventImages: ImageData[] = [];

        return images.reduce((acc: any[], image, index) => {
            if (!image.event_key && !image.datetime) {
                noEventImages.push(image);
                return acc;
            }

            if (image.event_key !== currentEventKey) {
                currentEventKey = image.event_key;
                currentEventIndex++;
                acc.push({
                    eventKey: image.event_key,
                    datetime: image.datetime,
                    images: [image],
                    index: currentEventIndex
                });
            } else {
                acc[acc.length - 1].images.push(image);
            }
            return acc;
        }, []).concat(noEventImages.length > 0 ? [{
            eventKey: null,
            datetime: null,
            images: noEventImages,
            index: 'no-event'
        }] : []);
    };

    const renderEventGroup = (group: any, index: number) => {
        const bgColor = !group.datetime ? '#f0f0f0' : // Light gray for undated events
                'white'; // Default white for dated events

        const title = group.datetime ? formatDateKey(group.datetime) :
            group.eventKey ? `Undated Event:` :
                'Unsorted Images:';

        return (
            // <Paper shadow="sm" radius="lg" withBorder p="xl" key={person.key} mt={index > 0 ? "lg" : undefined} style={{ backgroundColor: '#f0f0f0' }}>
            <Paper shadow={group.datetime? undefined: "sm"} radius={group.datetime? undefined : "lg"} withBorder={group.datetime? false: true} key={index} mt={index > 0 ? "lg" : undefined} mb={group.datetime? 'xl': undefined} p="md" style={{ backgroundColor: bgColor }}>
                <Title order={2}>{title}</Title>
                <Divider mb={'sm'} />
                <Flex
                    mt="md"
                    direction="row"
                    wrap="wrap"
                    gap={{ base: '3px', sm: '3px' }}
                    justify={{ sm: '3px' }}
                >
                    {group.images.map((image: ImageData, imgIndex: number) => (
                        <div style={{
                            position: 'relative',
                            width: 'calc((100% - 20px) / 6)',
                            aspectRatio: 1 / 1,
                        }}>
                        <ImageWithSkeleton
                            key={`${group.eventKey}-${imgIndex}`}
                            src={image.location}
                            alt={`Photo ${image.datetime ? `taken on ${image.datetime}` : 'with unknown date'}`}
                            onClick={() => handleFullscreenImage(image.location)}
                        />
                        </div>
                    ))}
                </Flex>
            </Paper>
        );
    };

    const LoadingSkeleton = () => (
        <>
            {[1, 2, 3].map((groupIndex) => (
                <Paper key={`skeleton-group-${groupIndex}`} mt={groupIndex > 0 ? "lg" : undefined} mb='xl'>
                    <Skeleton height={30} width="50%" mb="sm" />
                    <Divider mb={'sm'} />
                    <Flex
                        mt="md"
                        direction="row"
                        wrap="wrap"
                        gap={{ base: '3px', sm: '3px' }}
                        justify={{ sm: '3px' }}
                    >
                        {[1, 2, 3, 4, 5, 6].map((_, index) => (
                            <Skeleton
                                key={`skeleton-image-${groupIndex}-${index}`}
                                style={{
                                    width: 'calc((100% - 20px) / 6)',
                                    aspectRatio: 1 / 1,
                                    cursor: 'pointer',
                                }}
                            />
                        ))}
                    </Flex>
                </Paper>
            ))}
        </>
    );

    const groupedImages = groupImages(images);

    return (
        <Flex direction="column" style={{ width: '100vw' }}>
            <Title order={1}>Gallery</Title>
            <Divider mt='xs' />
            <Blockquote color="blue" icon={<IconInfoCircle />} mt='lg' mb='lg'>
                This gallery tab is where all of your sorted images will be displayed after you have finished organizing them!
            </Blockquote>

            {loading && images.length === 0 ? (
                <LoadingSkeleton />
            ) : (
                groupedImages.map((group, index) => renderEventGroup(group, index))
            )}

            {loading && (
                <Flex justify="center" mt="xl">
                    <Loader size="lg" />
                </Flex>
            )}

            {!hasMore && !loading && (
                <Flex justify="center" mt="xl">
                    <p>No more images to load</p>
                </Flex>
            )}

            <ImageModal imageUrl={fullscreenImage} onClose={handleCloseFullscreen} />
        </Flex>
    );
}