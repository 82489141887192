import React, {Dispatch, SetStateAction, useState} from 'react';
import {Text, Divider, Paper, Flex, BackgroundImage, Grid, PaperProps, Center, Box, Container, SimpleGrid, Image} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import * as AuthHandlers from './LoginHandlers';
import {PasswordChangeForm} from './PasswordChangeForm';
import Login from './Login';
import SignUp from './SignUp';
import Verification from './VerifyEmail';
import Waitlist from './Waitlist';
import {AuthenticationResultType, GetUserCommandOutput} from "@aws-sdk/client-cognito-identity-provider";
export enum AuthStateOptions {
    Login = 0,
    Register = 1,
    ForgetPassword = 2,
    ResetPassword = 3,
    SetPasswordAtFirstLogin = 4,
    Verify = 5,
    LoginAfterRegistration = 6,
    JoinWaitlist = 7,
}

interface AuthenticationTitleProps extends PaperProps {
    onSuccessfulSignIn: (authResults: AuthenticationResultType, userResults: GetUserCommandOutput) => void;
}

export function AuthenticationTitle({ onSuccessfulSignIn, ...props }: AuthenticationTitleProps) {
    const [username, setUsername] = useState("");
    const [session, setSession] = useState("");
    const [authState, changeAuthState] = useState<AuthStateOptions>(AuthStateOptions.Login); // Assuming "login" is the initial session state

    const isMobile = useMediaQuery('(max-width: 768px)');

    const handleAppleSignIn = () => {
        // todo: implement
    }
    const handleAppleSignUp = () => {
        // todo: implement
    }
    const handleGoogleSignIn = () => {
        // todo: implement
    }
    const handleGoogleSignUp = () => {
        // todo: implement
    }
    const handleEmailSignIn = async (username: string, password: string, setErrorMessage: (message: string) => void, clearForm: () => void) => {
        await AuthHandlers.handleSignIn(username, password, setErrorMessage, clearForm, onSuccessfulSignIn, setSession, setUsername, changeAuthState);
    };

    const handleEmailSignUp = async (values: { firstName: string; lastName: string; email: string; password: string; terms: boolean }) => {
        // todo: make sure terms are agreed to
        await AuthHandlers.handleSignUp(values.email, values.password, values.firstName, values.lastName, setSession, setUsername, changeAuthState);
    };

    const handlePasswordChangeSubmit = async (firstName: string, lastName: string, newPassword: string) => {
        await AuthHandlers.handlePasswordChangeSubmit(session, username, firstName, lastName, newPassword, onSuccessfulSignIn)
    };

    const handleVerifyEmail = async (code: string, onCodeError: (code: boolean) => void) => {
        await AuthHandlers.verifyEmailWithCode(username, code, changeAuthState, onCodeError)
    }

    return (
        <Grid>
            {isMobile ? (
                <BackgroundImage
                    src="./elena-ferrer-AigO5TWibjk-unsplash.jpg"
                    radius="sm"
                    style={{ height: '105vh' }}
                >
                    <Center style={{ height: '100%' }}> {/* This will center the Paper horizontally and vertically */}
                        <Paper
                            mt="-10%"
                            radius="md"
                            p="xl"
                            withBorder
                            {...props}
                            style={{ width: '90%', maxWidth: '600px' }}  // maxWidth ensures the Paper won't be too wide
                        >
                            {authState === AuthStateOptions.Login && (
                                <Login
                                    isFirstLogin={false}
                                    changeAuthState={changeAuthState}
                                    handleAppleSignIn={handleAppleSignIn}
                                    handleEmailSignIn={handleEmailSignIn}
                                    handleGoogleSignIn={handleGoogleSignIn}
                                />
                            )}
                            {authState === AuthStateOptions.Register && (
                                <SignUp
                                    changeAuthState={changeAuthState}
                                    handleAppleSignUp={handleAppleSignUp}
                                    handleEmailSignUp={handleEmailSignUp}
                                    handleGoogleSignUp={handleGoogleSignUp}
                                />
                            )}
                            {authState === AuthStateOptions.LoginAfterRegistration && (
                                <Login
                                    isFirstLogin={true}
                                    changeAuthState={changeAuthState}
                                    handleAppleSignIn={handleAppleSignIn}
                                    handleEmailSignIn={handleEmailSignIn}
                                    handleGoogleSignIn={handleGoogleSignIn}
                                />
                            )}
                            {authState === AuthStateOptions.SetPasswordAtFirstLogin && (
                                <PasswordChangeForm onSubmit={handlePasswordChangeSubmit} />
                            )}
                            {authState === AuthStateOptions.Verify && (
                                <Verification handleVerification={handleVerifyEmail} />
                            )}
                            {authState === AuthStateOptions.JoinWaitlist && (
                                <Waitlist changeAuthState={changeAuthState} />
                            )}
                        </Paper>
                    </Center>
                </BackgroundImage>
            ) : (
                <>
                    <Grid.Col span={5}>
                        <Container style={{ width: '40vw', display: 'flex', justifyContent: 'center' }}>
                            <Paper mt="30%" radius="md" p="xl" {...props} style={{ width: '80%' }}>
                                <Text fs="italic" size="36px" fw={700} mb="xl" color="cyan.8">
                                    Welcome to SortMyShoebox!
                                </Text>
                                {authState === AuthStateOptions.Login && (
                                    <Login
                                        isFirstLogin={false}
                                        changeAuthState={changeAuthState}
                                        handleAppleSignIn={handleAppleSignIn}
                                        handleEmailSignIn={handleEmailSignIn}
                                        handleGoogleSignIn={handleGoogleSignIn}
                                    />
                                )}
                                {authState === AuthStateOptions.Register && (
                                    <SignUp
                                        changeAuthState={changeAuthState}
                                        handleAppleSignUp={handleAppleSignUp}
                                        handleEmailSignUp={handleEmailSignUp}
                                        handleGoogleSignUp={handleGoogleSignUp}
                                    />
                                )}
                                {authState === AuthStateOptions.LoginAfterRegistration && (
                                    <Login
                                        isFirstLogin={true}
                                        changeAuthState={changeAuthState}
                                        handleAppleSignIn={handleAppleSignIn}
                                        handleEmailSignIn={handleEmailSignIn}
                                        handleGoogleSignIn={handleGoogleSignIn}
                                    />
                                )}
                                {authState === AuthStateOptions.SetPasswordAtFirstLogin && (
                                    <PasswordChangeForm onSubmit={handlePasswordChangeSubmit} />
                                )}
                                {authState === AuthStateOptions.Verify && (
                                    <Verification handleVerification={handleVerifyEmail} />
                                )}
                                {authState === AuthStateOptions.JoinWaitlist && (
                                    <Waitlist changeAuthState={changeAuthState} />
                                )}
                            </Paper>
                        </Container>
                    </Grid.Col>
                    <Grid.Col span={7}>
                        <Box style={{ height: '100vh', width: '60vw' }}>
                            <Image
                                src="./elena-ferrer-AigO5TWibjk-unsplash.jpg"
                                alt="Login illustration"
                                fit="cover"
                                height="100%"
                            />
                        </Box>
                    </Grid.Col>
                </>
            )}
        </Grid>
    );
}

//     return (
//         <Grid>
//             <Grid.Col span={5}>
//                 <Container style={(theme) => ({
//                         // backgroundColor: theme.colors.gray[1], // Light beige color
//                         // height: '100vh', // Full viewport height
//                         width: '40vw',
//                         display: 'flex',
//                         // alignItems: 'center',
//                         justifyContent: 'center',
//                 })}>
//
//                     <Paper mt="30%" radius="md" p="xl" {...props} style={(theme) => ({
//                         // backgroundColor: theme.colors.gray[1], // Light beige color
//                         // height: '60vh', // Full viewport height
//                         width: '80%',
//                         // display: 'flex',
//                         // alignItems: 'center',
//                         // justifyContent: 'center',
//                     })}>
//                         {/*<Image src="./logo.png" mb="5%" alt="Company Logo" style={{width: 104}}/>*/}
//                         <Text fs="italic" size="36px" fw={700} mb="xl" color="cyan.8">
//                             Welcome to SortMyShoebox!
//                         </Text>
//                         {/*<Text size="20px" fw={400} color="dimmed" lh={1.5} mb="12%">*/}
//                         {/*    Preserve your memories in minutes, not months*/}
//                         {/*</Text>*/}
//                         {authState === AuthStateOptions.Login && (
//                             <Login
//                                 isFirstLogin={false}
//                                 changeAuthState={changeAuthState}
//                                 handleAppleSignIn={handleAppleSignIn}
//                                 handleEmailSignIn={handleEmailSignIn}
//                                 handleGoogleSignIn={handleGoogleSignIn}
//                             />
//                         )}
//                         {authState === AuthStateOptions.Register && (
//                             <SignUp
//                                 changeAuthState={changeAuthState}
//                                 handleAppleSignUp={handleAppleSignUp}
//                                 handleEmailSignUp={handleEmailSignUp}
//                                 handleGoogleSignUp={handleGoogleSignUp}
//                             />
//                         )}
//                         {authState === AuthStateOptions.LoginAfterRegistration && (
//                             <Login
//                                 isFirstLogin={true}
//                                 changeAuthState={changeAuthState}
//                                 handleAppleSignIn={handleAppleSignIn}
//                                 handleEmailSignIn={handleEmailSignIn}
//                                 handleGoogleSignIn={handleGoogleSignIn}
//                             />
//                         )}
//                         {authState === AuthStateOptions.SetPasswordAtFirstLogin && (
//                             <PasswordChangeForm onSubmit={handlePasswordChangeSubmit}/>
//                         )}
//                         {authState === AuthStateOptions.Verify && (
//                             <Verification handleVerification={handleVerifyEmail}/>
//                         )}
//                         {authState === AuthStateOptions.JoinWaitlist && (
//                             <Waitlist changeAuthState={changeAuthState}/>
//                         )}
//                     </Paper>
//                 </Container>
//             </Grid.Col>
//             <Grid.Col span={7}>
//             <Box style={{
//                 height: '100vh', // Full viewport height
//                 width: '60vw',
//             }}>
//                 <Image
//                     src="./elena-ferrer-AigO5TWibjk-unsplash.jpg"
//                     alt="Login illustration"
//                     fit="cover"
//                     height="100%"
//                 />
//             </Box>
//             </Grid.Col>
//             </Grid>
//     );
// }