import React, {Dispatch, SetStateAction, useState} from 'react';
import {useForm} from '@mantine/form';
import {Group, TextInput, PasswordInput, Button, Stack, Anchor, Text, Divider, Alert} from '@mantine/core';
import {AppleButton} from './AppleButton';
import {AuthStateOptions} from './Authenticate';
import {GoogleButton} from './GoogleButton';
import {IconArrowRight} from "@tabler/icons-react";


interface LoginProps {
    isFirstLogin: boolean;
    handleAppleSignIn: () => void;
    handleGoogleSignIn: () => void;
    handleEmailSignIn: (username: string, password: string, setErrorMessage: (message: string) => void, clearForm: () => void) => void;
    changeAuthState: (option: AuthStateOptions) => void;

}

const Login: React.FC<LoginProps> = ({isFirstLogin, handleAppleSignIn, handleGoogleSignIn, handleEmailSignIn, changeAuthState}) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    //const [passwordChangeRequired, setPasswordChangeRequired] = useState(false);
    const form = useForm({
        initialValues: {
            email: '',
            password: '',
        },
        validate: {
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
            password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
        },
    });

    const clearForm = () => {
        form.setFieldValue('password', '')
    };

    return (
        <><Text size="xl" fw={500} mb="md">
            Early Access Invite? Log In Here:
            {/*Welcome to SortMyShoebox!*/}
        </Text>
            {/*todo: implement 3rd party oAuth*/}
            {/*<Group grow mb="md" mt="md">*/}
            {/*    <AppleButton radius="xl" onClick={handleAppleSignIn}>Sign in with Apple</AppleButton>*/}
            {/*    <GoogleButton radius="xl" onClick={handleGoogleSignIn}>Sign in with Google</GoogleButton>*/}
            {/*</Group>*/}
            {/*<Divider label="Or sign in with email" labelPosition="center" my="lg"/>*/}
            {isFirstLogin && (
                <Alert title="Success!" color="green" mb="lg">
                    Your account has been created successfully! Login below to get started.
                </Alert>
            )}
            {errorMessage && (
                <Alert title="Error" color="red" mb="lg">
                    {errorMessage}
                </Alert>
            )}
            <form
                //onSubmit={(event) => form.onSubmit(() => handleEmailSignIn(form.values.email, form.values.password, setPasswordChangeRequired))(event)}>
                onSubmit={(event) => form.onSubmit(() => handleEmailSignIn(form.values.email, form.values.password, setErrorMessage, clearForm))(event)}>

                <Stack>
                    <TextInput
                        required
                        label="Email"
                        placeholder="name@mail.com"
                        value={form.values.email}
                        onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                        error={form.errors.email}
                        radius="md"
                    />
                    <PasswordInput
                        required
                        label="Password"
                        placeholder="Your password"
                        type="password"
                        value={form.values.password}
                        onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                        error={form.errors.password}
                        radius="md"
                    />
                </Stack>
                <Group justify="space-between" mt="xl">
                    {/*<Anchor component="button" type="button" c="dimmed" size="xs"*/}
                    {/*        onClick={() => changeAuthState(AuthStateOptions.Register)}>*/}
                    {/*    Don't have an account yet? Sign Up*/}
                    {/*</Anchor>*/}
                    <Anchor component="button" type="button" c="dimmed" size="md"
                            onClick={() => changeAuthState(AuthStateOptions.JoinWaitlist)}
                            style={{ display: 'flex', alignItems: 'center' }}>
                        Want an Invite? Join the Waitlist
                        <IconArrowRight size={18} style={{ marginLeft: '4px' }} />
                    </Anchor>
                    <Button type="submit" radius="xl" color="cyan.8">
                        Sign In
                    </Button>
                </Group>
            </form>
        </>
    );
}

export default Login;