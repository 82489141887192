import {
    CognitoIdentityProviderClient,
    InitiateAuthCommand, InitiateAuthCommandInput, InitiateAuthCommandOutput,
    SignUpCommand, SignUpCommandInput, SignUpCommandOutput,
    ConfirmSignUpCommand, ConfirmSignUpCommandInput, ConfirmSignUpCommandOutput,
    RespondToAuthChallengeCommand, RespondToAuthChallengeCommandInput,
    GetUserCommand, GetUserCommandInput, GetUserCommandOutput, AuthenticationResultType
} from '@aws-sdk/client-cognito-identity-provider';
import {AuthStateOptions} from './Authenticate';

// todo: hide!
const client = new CognitoIdentityProviderClient({ region: 'us-west-2' });
const clientId = "4js1rtmaqhg5tr7sbs2emq4j3f"

export async function handleSignIn(email: string, password: string,
                                   setErrorMessage: (message: string) => void,
                                   clearForm: () => void,
                                   onSuccessfulSignIn: (authResults: AuthenticationResultType, userResults: GetUserCommandOutput) => void,
                                   setSession: (session: string) => void,
                                   setUsername: (username: string) => void,
                                   changeAuthState: (option: AuthStateOptions) => void) {

    const authenticationDetails: InitiateAuthCommandInput = {
        AuthFlow: "USER_PASSWORD_AUTH",
        AuthParameters: {
            "USERNAME": email,
            "PASSWORD": password,
        },
        ClientId: clientId,
    };

    try {
        const command = new InitiateAuthCommand(authenticationDetails);
        const response: InitiateAuthCommandOutput = await client.send(command);
        console.log(response)


        if (response.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
            changeAuthState(AuthStateOptions.SetPasswordAtFirstLogin)
            setSession(response.Session || "");
            setUsername(email)
            return;
        }

        if (response.AuthenticationResult) {
            let authResults: AuthenticationResultType = response.AuthenticationResult
            console.log(authResults.IdToken)
            const getUserInput : GetUserCommandInput = {
                AccessToken: response.AuthenticationResult?.AccessToken || ""
            }
            const getUsers = new GetUserCommand(getUserInput)
            const userResults: GetUserCommandOutput = await client.send(getUsers);
            console.log(userResults)

            onSuccessfulSignIn(authResults, userResults)
        }
    } catch (error) {
        console.error('Sign-in error:', error);
        setErrorMessage('Incorrect username or password. Please try again.');
        clearForm();  // Clear the email and password fields
    }
}

export async function handleSignUp(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    setSession: (session: string) => void,
    setUsername: (username: string) => void,
    changeAuthState: (option: AuthStateOptions) => void
) {
    const signUpDetails: SignUpCommandInput = {
        ClientId: clientId,
        Username: email,
        Password: password,
        UserAttributes: [
            {
                Name: "email",
                Value: email,
            },
            {
                Name: "given_name",
                Value: firstName,
            },
            {
                Name: "family_name",
                Value: lastName,
            },
        ],
    };

    try {
        // Sign up the user
        const command = new SignUpCommand(signUpDetails);
        const response = await client.send(command);
        console.log(response);

        // Extract the 'sub' (unique user ID) from the response
        const userId: string | undefined = response.UserSub;

        // Enforce that userId is not undefined
        if (!userId) {
            throw new Error('User ID (sub) is undefined. Unable to proceed with sign up flow.');
        }

        // Set username for session and redirect to verify state
        setUsername(email);
        changeAuthState(AuthStateOptions.Verify);

        // Track terms of service agreement using the valid userId
        await trackTOSAgreement(userId);
    } catch (error) {
        console.error('Sign-up error:', error);
    }
}

// Function to track TOS agreement in DynamoDB via API Gateway
const trackTOSAgreement = async (userId: string) => {
    const agreementData = {
        userId: userId,  // Use Cognito 'sub' as the user ID
        tosAgreementTimestamp: new Date().toISOString(),
        tosVersion: '1.0',  // Optional: Use the actual TOS version
    };

    try {
        const response = await fetch('https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/user/tos', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(agreementData),
        });

        if (!response.ok) {
            throw new Error('Failed to track TOS agreement');
        }
        console.log('TOS agreement tracked successfully');
    } catch (error) {
        console.error('Error saving TOS agreement:', error);
    }
};

export async function verifyEmailWithCode(
    username: string,
    verificationCode: string,
    changeAuthState: (option: AuthStateOptions) => void,
    onCodeError: (codeWrong: boolean) => void
) {
    const confirmSignUpInput: ConfirmSignUpCommandInput = {
        ClientId: clientId, // Replace with your actual client ID
        Username: username,
        ConfirmationCode: verificationCode,
    };

    try {
        const command = new ConfirmSignUpCommand(confirmSignUpInput);
        const response = await client.send(command);
        console.log(response);
        changeAuthState(AuthStateOptions.LoginAfterRegistration); // Redirect to the login state
    } catch (error) {
        onCodeError(true)
        console.error('Confirmation error:', error);
    }
}

export async function handlePasswordChangeSubmit(session: string, username: string, firstName: string, lastName: string, newPassword: string,  onSuccessfulSignIn: (authResults: AuthenticationResultType, userResults: GetUserCommandOutput) => void) {
    //todo: refactor and remove onSuccessfulSignIn callback
    let passwordChangeDetails: RespondToAuthChallengeCommandInput = {
        "ChallengeName": 'NEW_PASSWORD_REQUIRED',
        "ChallengeResponses": { "NEW_PASSWORD": newPassword, "USERNAME": username, "userAttributes.given_name": firstName, "userAttributes.family_name": lastName},
        "ClientId": clientId,
        "Session": session
    };

    try {
        const command = new RespondToAuthChallengeCommand(passwordChangeDetails);
        const response = await client.send(command);
        // Track terms of service agreement using the valid userId
        await trackTOSAgreement(username);
        console.log(response)
        if (response.AuthenticationResult) {
            let authResults: AuthenticationResultType = response.AuthenticationResult
            console.log(authResults.IdToken)
            const getUserInput : GetUserCommandInput = {
                AccessToken: response.AuthenticationResult?.AccessToken || ""
            }
            const getUsers = new GetUserCommand(getUserInput)
            const userResults: GetUserCommandOutput = await client.send(getUsers);
            console.log(userResults)

            onSuccessfulSignIn(authResults, userResults)
        }
        // console.log("login success!", response.AuthenticationResult)
    } catch (error) {
        console.error('Sign-in error:', error);
        throw error;
    }
}

export async function handleAppleSignIn() {
    // todo: implement
}

export async function handleAppleSignUp() {
    // todo: implement
}

export async function handleGoogleSignIn() {
    // todo: implement
}

export async function handleGoogleSignUp() {
    // todo: implement
}

export async function handleEmailSignUp() {
    // todo: implement
}
