import {
    Blockquote,
    Divider,
    Flex,
    Title,
    Stack, Group, Card, Skeleton, Paper
} from "@mantine/core";
import {AuthenticationResultType} from "@aws-sdk/client-cognito-identity-provider";
import {IconInfoCircle} from "@tabler/icons-react";
import React, {useEffect, useState} from "react";
import {NewEvent} from "./GroupNewEvent";
import {AddToEvent} from "./GroupAddToEvent";
import {MergeEvents} from "./GroupMergeEvents";
import axios from 'axios';
import {GroupSuggestion, SuggestionNewEvent, SuggestionAddImageToEvent, SuggestionMergeEvents} from './GroupInterfaces';

export interface GroupImagesProps {
    userId: string;
    auth: AuthenticationResultType;
}

export function GroupImages({userId, auth}: GroupImagesProps) {
    const [suggestions, setSuggestions] = useState<GroupSuggestion[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasMoreSuggestions, setHasMoreSuggestions] = useState<boolean>(true);

    useEffect(() => {
        fetchGalleryImages();
    }, []);

    const fetchGalleryImages = async () => {
        if (!hasMoreSuggestions) return;

        setIsLoading(true);
        try {
            let apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/event/suggestions";
            let id_token = auth.IdToken;

            const response = await axios.get(apiUrl, {
                params: {
                    sub: userId
                },
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });

            const responseBody = JSON.parse(response.data.body);

            const newEventSuggestions: SuggestionNewEvent[] = responseBody.new_event_suggestions.map((suggestion: any) => ({
                key: suggestion.key,
                score: suggestion.score,
                type: 'newEvent',
                images: suggestion.images
            }));

            const addToEventSuggestions: SuggestionAddImageToEvent[] = responseBody.add_to_event_suggestions.map((suggestion: any) => ({
                key: suggestion.key,
                score: suggestion.score,
                type: 'addImageToEvent',
                image: suggestion.image,
                event: suggestion.event
            }));

            const mergeEventsSuggestions: SuggestionMergeEvents[] = responseBody.merge_event_suggestions.map((suggestion: any) => ({
                key: suggestion.key,
                score: suggestion.score,
                type: 'mergeEvents',
                event1: suggestion.event1,
                event2: suggestion.event2
            }));

            const combinedSuggestions: GroupSuggestion[] = [...newEventSuggestions, ...addToEventSuggestions, ...mergeEventsSuggestions];

            if (combinedSuggestions.length === 0) {
                setHasMoreSuggestions(false);
            } else {
                setSuggestions(prevSuggestions => [...prevSuggestions, ...combinedSuggestions]);
            }

        } catch (error) {
            console.error('Error fetching images:', error);
            setHasMoreSuggestions(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSuggestionResponse = async (suggestionKey: string) => {
        setSuggestions(prevSuggestions => prevSuggestions.filter(s => s.key !== suggestionKey));

        if (suggestions.length === 1) {
            // This was the last suggestion, fetch more
            await fetchGalleryImages();
        }
    };

    const postNewEvent = async (seed: string, selected: string[], unselected: string[]): Promise<boolean> => {
        try {
            const newEventUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/event/new";
            const id_token = auth.IdToken;

            let data = {
                sub: userId,
                include: selected,
                exclude: unselected
            }
            console.log(data)

            const response = await axios.post(newEventUrl,
                {
                    sub: userId,
                    include: selected,
                    exclude: unselected
                },
                {
                    headers: {
                        'Authorization': `Bearer ${id_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status === 200) {
                return true;
            } else {
                console.error(`Backend Error: Status code ${response.status}, Response data: ${JSON.stringify(response.data)}`);
                return false;
            }
        } catch (error) {
            console.error('Error creating event:', error);
            return false;
        }
    };

    const postAddImageToEvent = async (eventKey: string, imageKey: string, shouldAdd: boolean): Promise<boolean> => {
        try {
            const newEventUrl = "https://kc5a6afno5.execute-api.us-west-2.amazonaws.com/test3/add-image-to-event";
            const id_token = auth.IdToken;

            const response = await axios.post(newEventUrl,
                {
                    event_key: eventKey,
                    image_key: imageKey,
                    should_add: shouldAdd,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${id_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status === 200) {
                return true;
            } else {
                console.error(`Backend Error: Status code ${response.status}, Response data: ${JSON.stringify(response.data)}`);
                return false;
            }
        } catch (error) {
            console.error('Error creating event:', error);
            return false;
        }
    };

    const postMergeEvents = async (event1Key: string, event2Key: string, shouldMerge: boolean): Promise<boolean> => {
        try {
            const newEventUrl = "https://kc5a6afno5.execute-api.us-west-2.amazonaws.com/test3/merge-events";
            const id_token = auth.IdToken;

            const response = await axios.post(newEventUrl,
                {
                    event1_key: event1Key,
                    event2_key: event2Key,
                    should_merge: shouldMerge,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${id_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status === 200) {
                return true;
            } else {
                console.error(`Backend Error: Status code ${response.status}, Response data: ${JSON.stringify(response.data)}`);
                return false;
            }
        } catch (error) {
            console.error('Error creating event:', error);
            return false;
        }
    };

    const closeNewEventSuggestionAfterSuccessfulSubmit = (suggestion: SuggestionNewEvent) => {
        return async (seed: string, selected: string[], unselected: string[]) => {
            const success = await postNewEvent(seed, selected, unselected);

            if (success) {
                if (suggestions.length === 1) {
                    setIsLoading(true);
                    await fetchGalleryImages();
                    //fetchSuggestionsFromDemo()
                } else {
                    setSuggestions(prevSuggestions => prevSuggestions.filter(s => s.key !== suggestion.key));
                }
                // setSuggestions(prevSuggestions => prevSuggestions.filter(s => s.key !== suggestion.key));
                // if (suggestions.length === 0) {
                //     fetchSuggestionsFromDemo()
                // }
            }
        };
    };

    const closeAddImageSuggestionAfterSuccessfulSubmit = (suggestion: SuggestionAddImageToEvent) => {
        return async (shouldAdd: boolean) => {
            const success = await postAddImageToEvent(suggestion.event.key, suggestion.image.key, shouldAdd);

            if (success) {
                if (suggestions.length === 1) {
                    setIsLoading(true);
                    await fetchGalleryImages();
                } else {
                    setSuggestions(prevSuggestions => prevSuggestions.filter(s => s.key !== suggestion.key));
                }
                // setSuggestions(prevSuggestions => prevSuggestions.filter(s => s.key !== suggestion.key));
                // if (suggestions.length === 0) {
                //     fetchSuggestionsFromDemo()
                // }
            }
        };
    };

    const closeMergeEventSuggestionAfterSuccessfulSubmit = (suggestion: SuggestionMergeEvents) => {
        return async (shouldMerge: boolean) => {
            const success = await postMergeEvents(suggestion.event1.key, suggestion.event2.key, shouldMerge);

            if (success) {
                if (suggestions.length === 1) {
                    setIsLoading(true);
                    await fetchGalleryImages();
                } else {
                    setSuggestions(prevSuggestions => prevSuggestions.filter(s => s.key !== suggestion.key));
                }
                // setSuggestions(prevSuggestions => prevSuggestions.filter(s => s.key !== suggestion.key));
                // if (suggestions.length === 0) {
                //     fetchSuggestionsFromDemo()
                // }
            }
        };
    };

    const suggestionComponents = suggestions.map((suggestion) => {
        if (suggestion.type === 'newEvent') {
            return (
                <NewEvent key={suggestion.key} group={suggestion}
                          onSubmit={closeNewEventSuggestionAfterSuccessfulSubmit(suggestion)}/>
            )
        } else if (suggestion.type === 'addImageToEvent') {
            return <AddToEvent key={suggestion.key} suggestion={suggestion}
                               onSubmit={closeAddImageSuggestionAfterSuccessfulSubmit(suggestion)}/>
        } else if (suggestion.type === 'mergeEvents') {
            return <MergeEvents key={suggestion.key} suggestion={suggestion} onSubmit={closeMergeEventSuggestionAfterSuccessfulSubmit(suggestion)}/>
        } else {
            console.log("suggestion type not handled");
            return null;
        }
    }).filter(Boolean);


    const EventSuggestionSkeleton = () => {
        return (
            <div>
                {[...Array(3)].map((_, index) => (
                    <Paper shadow="sm" radius="lg" withBorder p="xl" key={index} mt={index > 0 ? "lg" : undefined}
                           style={{backgroundColor: '#f0f0f0'}}>
                        <Skeleton height={28} width="40%" radius="xl"/>
                        <Skeleton height={1} width="100%" mt="sm"/>
                        <Flex
                            mt="md"
                            direction="row"
                            wrap="wrap"
                            gap={{base: '3px', sm: '3px'}}
                            justify={{sm: '3px'}}
                        >
                            {[...Array(5)].map((_, imgIndex) => (
                                <Skeleton
                                    key={imgIndex}
                                    width="calc((100% - 12px) / 5)"
                                    height={0}
                                    style={{paddingBottom: 'calc((100% - 12px) / 5)'}}
                                    radius="sm"
                                />
                            ))}
                        </Flex>
                        <Group justify="flex-end" gap="sm" mt="lg">
                            <Skeleton height={36} width={80} radius="md"/>
                            <Skeleton height={36} width={120} radius="md"/>
                        </Group>
                    </Paper>
                ))}
            </div>
        );
    };

    return (
        <Flex direction="column" style={{width: '100vw'}}>
            <Title order={1}>Group Images</Title>
            <Divider mt='xs'/>
            <Blockquote color="blue" icon={<IconInfoCircle/>} mt='lg' mb='lg'>
                Confirming dates ensures accurate chronological organization of your photos, allowing you to relive your
                memories in the order they occurred. This process enhances the overall coherence of your photo
                collection, making it easier to navigate and reminisce about significant moments.
            </Blockquote>
            {isLoading ? (
                <EventSuggestionSkeleton/>
            ) : suggestionComponents.length > 0 ? (
                suggestionComponents
            ) : (
                <Paper p="md" withBorder>
                    <Title order={3}>No more suggestions available</Title>
                    <p>You've processed all available suggestions. Great job organizing your photos!</p>
                </Paper>
            )}
        </Flex>
    );
}