import React, { useState, useEffect, useRef } from 'react';
import { Image, Skeleton } from '@mantine/core';

interface ImageWithSkeletonProps {
    src: string;
    alt: string;
    onClick: () => void;
}

const ImageWithSkeleton = React.memo(({ src, alt, onClick }: ImageWithSkeletonProps) => {
    const [loaded, setLoaded] = useState(false);
    const imgRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        if (imgRef.current && imgRef.current.complete) {
            setLoaded(true);
        }
    }, [src]);

    const handleImageLoad = () => {
        setLoaded(true);
    };

    return (
            <>
            {!loaded && (
                <Skeleton height="100%" width="100%" radius="md" animate={true} />
            )}
            <Image
                ref={imgRef}
                src={src}
                alt={alt}
                fit="cover"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    aspectRatio: 1 / 1,
                    opacity: loaded ? 1 : 0,
                    transition: 'opacity 0.3s ease-in-out',
                    cursor: 'pointer',
                }}
                onLoad={handleImageLoad}
                onClick={onClick}
            />
            </>
    );
});

export default ImageWithSkeleton;