import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Modal, Image, useMantineTheme } from '@mantine/core';

interface FaceLocation {
    height: number;
    name: string;
    width: number;
    x: number;
    y: number;
}

interface ImageDetails {
    dateTaken: string | null;
    faceLocations: FaceLocation[];
}

interface ImageModalProps {
    imageUrl: string | null;
    onClose: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ imageUrl, onClose }) => {
    const [imageDetails, setImageDetails] = useState<ImageDetails | null>(null);
    const theme = useMantineTheme();
    const imageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        const fetchImageDetails = async () => {
            try {
                const response = await axios.post<ImageDetails>('http://127.0.0.1:5000/api/image-details', { location: imageUrl });
                setImageDetails(response.data);
            } catch (error) {
                console.error('Error fetching image details:', error);
            }
        };

        if (imageUrl) {
            fetchImageDetails();
        }
    }, [imageUrl]);

    const handleClose = () => {
        setImageDetails(null);
        onClose();
    };

    const calculateOverlayPosition = (location: FaceLocation) => {
        if (!imageRef.current) return {};

        const { naturalWidth, naturalHeight, clientWidth, clientHeight } = imageRef.current;
        const { x, y, width, height } = location;

        const scaleWidth = clientWidth / naturalWidth;
        const scaleHeight = clientHeight / naturalHeight;

        const left = (x * scaleWidth) / clientWidth * 100;
        const top = (y * scaleHeight) / clientHeight * 100;
        const overlayWidth = (width * scaleWidth) / clientWidth * 100;
        const overlayHeight = (height * scaleHeight) / clientHeight * 100;

        return { left, top, overlayWidth, overlayHeight };
    };

    return (
        <Modal
            opened={!!imageUrl}
            fullScreen
            onClose={onClose}
            styles={{
                overlay: { backgroundColor: 'rgba(0, 0, 0, 0.75)' },
                header: { backgroundColor: 'black' },
                body: { backgroundColor: 'black' },
            }}
        >
            <Image
                ref={imageRef}
                src={imageUrl || ''}
                style={{ height: '93.5vh', objectFit: 'contain' }}
            />
            {imageDetails && imageDetails.faceLocations.map((location, index) => {
                const { left, top, overlayWidth, overlayHeight } = calculateOverlayPosition(location);
                return (
                    <div
                        key={index}
                        style={{
                            position: 'absolute',
                            left: `${left}%`,
                            top: `${top}%`,
                            width: `${overlayWidth}%`,
                            height: `${overlayHeight}%`,
                            border: `2px solid ${theme.colors.red[6]}`,
                            borderRadius: '4px',
                            pointerEvents: 'none',
                        }}
                    >
                        <span
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                backgroundColor: theme.colors.red[6],
                                color: theme.white,
                                padding: '2px 4px',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                borderRadius: '4px',
                            }}
                        >
                            {location.name}
                        </span>
                    </div>
                );
            })}
        </Modal>
    );
};

export default ImageModal;