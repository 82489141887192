import React from 'react';
import { Flex, Blockquote, Card, Button, Text, Badge, Group, Image,  Switch} from '@mantine/core';
import {IconInfoCircle, IconChevronDown} from '@tabler/icons-react';

interface SortOptionProps {
    imageSrc: string;
    title: string;
    color: string;
    badgeContent: string;
    description: string;
    buttonText: string;
    buttonColor: string;
}

const SortOptionCard: React.FC<SortOptionProps> = ({ imageSrc, title, color, badgeContent, description, buttonText, buttonColor }) => (
    <Card mt="lg" shadow="sm" padding="lg" radius="md" withBorder style={{ width: '49%', aspectRatio: 1.75 }}>
        <Card.Section component="a" href="https://mantine.dev/">
            <Image
                src={imageSrc}
                height={200}
                alt="Norway"
            />
        </Card.Section>
        <Group justify="space-between" mt="md" mb="xs" >
            <Text size='lg' fw={500}>{title}</Text>
            <Badge ta='right' size='lg' color={color}>{badgeContent}</Badge>
        </Group>
        <Text size="md" c="dimmed">
            {description}
        </Text>
        <Button color={buttonColor} fullWidth  mt="md" radius="md">
            {buttonText}
        </Button>
    </Card>
);

const SortOptionsMenu: React.FC = () => (
    <Flex direction="column">
        <Blockquote color="blue" icon={<IconInfoCircle/>} mt="xl">
            Utilizing the AI-assisted menus for tasks like deleting duplicates, identifying faces, confirming groupings, and verifying dates not only streamlines your photo collection but also adds a deeply personal touch to each memory, ensuring every moment is cherished and easily accessible for reliving your most precious experiences.
        </Blockquote>
    <Flex
        wrap="wrap"
        direction={{ base: 'column', sm: 'row' }}
        gap={{ base: 'sm', sm: 'lg' }}
        justify={{ sm: 'left' }}
    >
        <SortOptionCard
            imageSrc="./duplicates.png"
            title="Delete Duplicates"
            color="red"
            badgeContent="25 Duplicates Detected"
            description="Quickly declutter your photo collection by identifying and removing duplicate images for a streamlined and organized gallery."
            buttonText="Delete Now"
            buttonColor="red"
        />
        <SortOptionCard
            imageSrc="./faces.webp"
            title="Identify Faces"
            color="blue"
            badgeContent="322 Faces to Id"
            description="Train our AI to recognize your loved ones by identifying and labeling faces, making it easier to search and share photos of specific individuals."
            buttonText="Identify Now"
            buttonColor="blue"
        />
        <SortOptionCard
            imageSrc="./groups.png"
            title="Confirm Groupings"
            color="green"
            badgeContent="5 Groups to Confirm"
            description="Validate and refine automatic photo groupings to ensure accurate categorization, creating a more meaningful and personalized organization of your memories."
            buttonText="Confirm Now"
            buttonColor="green"
        />
        <SortOptionCard
            imageSrc="./calender.jpeg"
            title="Verify Dates"
            color="pink"
            badgeContent="10 Dates to Verify"
            description="Enhance chronological accuracy by verifying and adjusting the dates of your photos, helping you relive moments in the order they occurred."
            buttonText="Verify Now"
            buttonColor="pink"
        />
    </Flex>
    </Flex>
);

export default SortOptionsMenu;
