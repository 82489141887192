import {Button, Flex, Image, Paper, Title} from "@mantine/core";
import {IconLayersUnion} from "@tabler/icons-react";
import {Carousel} from "@mantine/carousel";
import {SuggestionMergeEvents} from "@components/GroupInterfaces";

interface MergeEventsProps {
    suggestion: SuggestionMergeEvents,
    onSubmit: (shouldMerge: boolean) => void;
}

export function MergeEvents({suggestion, onSubmit}: MergeEventsProps) {
    return (
                <Paper shadow="sm" radius="lg" withBorder p="xl" key={suggestion.key}
                       style={{ marginBottom: '20px', padding: '10px', backgroundColor: '#f0f0f0' }}>
                    <Title order={3} mb={'lg'}>Are these groups from the same event?</Title>
                    <Flex
                        mt="md"
                        direction="row"
                        wrap="wrap"
                        gap={{ base: '3px', sm: '3px' }}
                        align="center"
                        justify="center"
                    >
                        {/* Carousel for Group Images */}
                        <div style={{ width: 'calc(((100% - 12px) / 5 ) * 2)'}}>
                            <Paper shadow="none" radius="md" style={{ backgroundColor: '#f0f0f0' }}>
                                {suggestion.event1.images.length <= 2 ? (
                                    // Render images directly if there are 2 or fewer
                                    <Flex justify="center" gap="3px">
                                        {suggestion.event1.images.map(image => (
                                            <div
                                                key={image.key}
                                                style={{
                                                    width: '50%',
                                                    aspectRatio: 1 / 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Image
                                                    src={image.thumbnail_url}
                                                    alt="Group Thumbnail"
                                                    style={{
                                                        width: '100%',
                                                        aspectRatio: 1 / 1,
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </Flex>
                                ) : (
                                    <Carousel
                                        withIndicators
                                        slideGap="3px"
                                        slideSize="50%"
                                        loop
                                        align="start"
                                        slidesToScroll={1}
                                        styles={{
                                            slide: {
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }
                                        }}
                                    >
                                        {suggestion.event1.images.map(image => (
                                            <Carousel.Slide key={image.key}>
                                                <div style={{
                                                    width: '100%',
                                                    aspectRatio: 1 / 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                    <Image
                                                        src={image.thumbnail_url}
                                                        alt="Group Thumbnail"
                                                        style={{
                                                            width: '100%',
                                                            aspectRatio: 1 / 1,
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </div>
                                            </Carousel.Slide>
                                        ))}
                                    </Carousel>
                                )}
                            </Paper>
                        </div>

                        {/* Divider/Arrow */}
                        <div style={{padding: '30px', textAlign: 'center', fontSize: '2rem', color: '#888' }}>
                            <IconLayersUnion size={70}/>
                        </div>

                        {/* Group2 Images */}
                        {/* Carousel for Group Images */}
                        <div style={{ width: 'calc(((100% - 12px) / 5 ) * 2)'}}>
                            <Paper shadow="none" radius="md" style={{ backgroundColor: '#f0f0f0' }}>

                                {suggestion.event2.images.length <= 2 ? (
                                    // Render images directly if there are 2 or fewer
                                    <Flex justify="center" gap="3px">
                                        {suggestion.event2.images.map(image => (
                                            <div
                                                key={image.key}
                                                style={{
                                                    width: '50%',
                                                    aspectRatio: 1 / 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Image
                                                    src={image.thumbnail_url}
                                                    alt="Group Thumbnail"
                                                    style={{
                                                        width: '100%',
                                                        aspectRatio: 1 / 1,
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </Flex>
                                ) : (
                                    <Carousel
                                        withIndicators
                                        slideGap="3px"
                                        slideSize="50%"
                                        loop
                                        align="start"
                                        slidesToScroll={1}
                                        styles={{
                                            slide: {
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }
                                        }}
                                    >
                                        {suggestion.event2.images.map(image => (
                                            <Carousel.Slide key={image.key}>
                                                <div style={{
                                                    width: '100%',
                                                    aspectRatio: 1 / 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                    <Image
                                                        src={image.thumbnail_url}
                                                        alt="Group Thumbnail"
                                                        style={{
                                                            width: '100%',
                                                            aspectRatio: 1 / 1,
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </div>
                                            </Carousel.Slide>
                                        ))}
                                    </Carousel>
                                )}
                            </Paper>
                        </div>
                    </Flex>
                    <Flex justify={{ sm: 'right' }} gap="sm" mt="md">
                        <Button onClick={() => onSubmit(false)} color="gray" variant="outline">No</Button>
                        <Button onClick={() => onSubmit(true)} color="blue">Yes, Merge</Button>
                    </Flex>
                </Paper>
    );
}
